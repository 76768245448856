import React, {FC} from 'react';
import {NavLink, useRouteMatch} from 'react-router-dom';

export interface NavListProps {
  items: {label: string, url: string, canShow?: boolean}[]
  className?: string
}

export const NavList: FC<NavListProps> = ({items, className}) => {
  const match = useRouteMatch();

  return <ul className="flex items-center justify-around w-full h-8 max-w-full text-gray-600 bg-azure bg-opacity-25 rounded-b-md">
    {items
      .filter(l => l.canShow === undefined || l.canShow)
      .map(i => (<li key={i.label}>
      <NavLink to={`${match.url}/${i.url}`}
        activeClassName="bg-pblue text-white" 
        className="px-4 py-2 text-center hover:text-white hover:bg-pblue rounded-b-md">
        {i.label}
      </NavLink>
    </li>))}
  </ul>
}

import React, {createContext, FC, useCallback, useContext, useEffect} from "react";
import {isAfter, subSeconds} from "date-fns";
import {Notification} from "../types";
import {useImmer} from "use-immer";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const useNotifications = () => {
  const [notifications, updateState] = useImmer<Notification[]>([]);

  useEffect(() => {
    const i = setInterval(() => {
      if (notifications.length === 0) return;

      updateState(d => {
        return d.filter(n => {
          return isAfter(n.added, subSeconds(new Date(), 15));
        });
      });
    }, 1000);

    return () => {
      clearInterval(i);
    }
  }, [notifications.length, updateState]);

  const removeNotification = useCallback((idx: number) => {
    updateState(d => {
      d.splice(idx, 1);
    });
  }, [updateState])

  const addNotification = useCallback((title: string, message?: string) => {
    updateState(d => {
      d.unshift({
        added: new Date(),
        title: title,
        message: message ?? ''
      })
    })
  }, [updateState]);

  return {
    notifications,
    addNotification,
    removeNotification
  }
}

interface NotificationCtxActions {
  addNotification: (title: string, message?: string) => void
}

const NotificationCtx = createContext<NotificationCtxActions>({
  addNotification: () => {
  }
});

export const useAppNotifications = () => useContext(NotificationCtx)

const NotificationItem = ({notification, onClose}: { notification: Notification, onClose: () => void }) => {
  return (<div className="rounded-md shadow-md bg-white flex flex-col p-4 mb-4 ventas-control">
    <div className="font-bold flex justify-between items-center">
      {notification.title}
      <FontAwesomeIcon
        icon={faTimesCircle}
        onClick={onClose}
        className="text-gray-300 hover:text-red-500 cursor-pointer text-sm"/>
    </div>
    <div className="py-4 text-sm">
      {notification.message}
    </div>
  </div>)
}

export const Notifications: FC = ({children}) => {
  const {notifications, addNotification, removeNotification} = useNotifications();

  return (<NotificationCtx.Provider value={{addNotification}}>
    {children}
    <div className="fixed bottom-8 right-8 ventas-control">
      {
        notifications.map((n, i) => (
          <NotificationItem key={n.added.getUTCMilliseconds()}
                            notification={n}
                            onClose={() => {
                              removeNotification(i)
                            }}/>))
      }
    </div>
  </NotificationCtx.Provider>)
}

import React, {FC, Fragment, useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {strings} from '../strings';
import {Permissions} from "../types";
import {usePermissions} from "../hooks";
import {useOnClickOutside} from "./DatePicker";

export const Header = () => {
  const location = useLocation();
  const [isHidden, setHiddenStatus] = useState(true);
  const permissions = usePermissions();

  useEffect(() => {
    setHiddenStatus(() => true)
  }, [location])

  const iref = useOnClickOutside<HTMLDivElement>(() => {
    setHiddenStatus(() => true)
  }, !isHidden);

  return <div className="header bg-pblue z-10">
    <nav className="container z-10 flex flex-wrap items-center justify-between p-4 mx-auto text-white bg-pblue">
      <span className="mr-6 text-xl font-semibold tracking-tight">
        {strings.title}
      </span>
      <div className="block xl:hidden">
        <button
          className="flex items-center px-3 py-2 text-gray-200 border border-teal-400 rounded hover:text-white hover:border-white"
          onClick={_ => setHiddenStatus(s => !s)}>
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <title>{strings.menu}</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
          </svg>
        </button>
      </div>
      <div
        ref={iref}
        className={`w-full block flex-grow xl:flex xl:items-center xl:w-auto xl:visible ${isHidden ? 'hidden' : ''}`}>
        <div className="text-sm xl:flex-grow xl:flex xl:justify-around">
          <Fragment>
            <RestrictedComponent permissions={permissions} module="catalogo">
              <NavLink className="nav-link" activeClassName="active-nav-link"
                       to="/catalogos">{strings.catalogos}</NavLink>
            </RestrictedComponent>
            <RestrictedComponent permissions={permissions} module="ventas">
              <NavLink className="nav-link" activeClassName="active-nav-link" to="/ventas">{strings.ventas}</NavLink>
            </RestrictedComponent>
            <RestrictedComponent permissions={permissions} module="entradasalida">
              <NavLink className="nav-link" activeClassName="active-nav-link" to="/notas">{strings.notas}</NavLink>
            </RestrictedComponent>
            <RestrictedComponent permissions={permissions} module="conversiones">
              <NavLink className="nav-link" activeClassName="active-nav-link"
                       to="/conversiones">{strings.conversiones}</NavLink>
            </RestrictedComponent>
            <RestrictedComponent permissions={permissions} module="reportes">
              <NavLink className="nav-link" activeClassName="active-nav-link"
                       to="/reportes">{strings.reportes}</NavLink>
            </RestrictedComponent>
            <RestrictedComponent permissions={permissions} module="productos">
              <NavLink className="nav-link" activeClassName="active-nav-link"
                       to="/productos">{strings.productos}</NavLink>
            </RestrictedComponent>
            <RestrictedComponent permissions={permissions} module="inventario">
              <NavLink className="nav-link" activeClassName="active-nav-link"
                       to="/inventarios">{strings.inventarios}</NavLink>
            </RestrictedComponent>
            <RestrictedComponent permissions={permissions} module="configuracion">
              <NavLink className="nav-link" activeClassName="active-nav-link"
                       to="/configuracion">{strings.configuracion}</NavLink>
            </RestrictedComponent>
          </Fragment>
        </div>
      </div>
    </nav>
  </div>
}

type Pages = keyof Omit<Permissions, 'sucursal'>;

interface RestrictedComponentProps {
  permissions: Permissions
  module: Pages
}

const RestrictedComponent: FC<RestrictedComponentProps> = ({permissions, module, children}) => {
  return permissions[module].canView ? <>{children}</> : null;
}

import React, {FC, useCallback, useEffect, useReducer, useRef} from 'react';
import {addMonths, addYears, format, getDaysInMonth, startOfMonth, subMonths, subYears} from 'date-fns';
import {faAngleDown, faAngleLeft, faAngleRight, faEraser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {es} from "date-fns/locale";

type ViewType = 'Month' | 'Day'
type View = { view: ViewType, date: Date }

type DSState = {
    selectedDate?: Date
    renderingDate: Date
    month: (number | undefined)[]
    year: Date[]
    currentView: View
    shown: boolean
}

type PrevMonth = { action: 'PrevMonth' };
type NextMonth = { action: 'NextMonth' };
type PrevYear = { action: 'PrevYear' };
type NextYear = { action: 'NextYear' };
type UnsetDate = { action: 'UnsetDate' };
type Toggle = { action: 'Toggle' };
type Close = { action: 'Close' };
type SetView = { action: 'SetView', view: View };
type SelectDate = { action: 'SelectDate', idx: number };
type SetDate = { action: 'SetDate', date?: Date };

type DSAction =
    PrevMonth
    | NextMonth
    | NextYear
    | PrevYear
    | UnsetDate
    | Toggle
    | Close
    | SetView
    | SelectDate
    | SetDate

const renderMonth = (d: Date): (number | undefined)[] => {
    const weekDayMonthStart = startOfMonth(d).getDay();
    const monthLength = getDaysInMonth(d)
    const padding: undefined[] = Array.from({length: weekDayMonthStart});
    const month: number[] = Array.from({length: monthLength}, (_, i) => i + 1);

    return [...padding, ...month];
}

const renderYear = (d: Date): Date[] => {
    return (Array.from({length: 11}, (_, i) => i + 1))
        .map(m => startOfMonth(new Date(d.getFullYear(), m)));
}

const isSelected = (renderingDate: Date, day: number, selectedDate?: Date) => {
    if (!selectedDate) return false;
    const selYear = selectedDate.getUTCFullYear();
    const selMonth = selectedDate.getMonth();
    const selDay = selectedDate.getDate();
    const rendYear = renderingDate.getUTCFullYear();
    const rendMonth = renderingDate.getMonth();

    return selYear === rendYear && selMonth === rendMonth && day === selDay;
}

const isMonthSelected = (renderingDate: Date, month: number, selectedDate?: Date) => {
    if (!selectedDate) return false;
    const selYear = selectedDate.getUTCFullYear();
    const selMonth = selectedDate.getUTCFullYear();
    const rendYear = renderingDate.getUTCFullYear();

    return rendYear === selYear && selMonth === month;
}

export interface DatePickerProps {
    onChange?: (date: Date | undefined) => void
    value?: Date
    className?: string
}

const reducer = (state: DSState, action: DSAction): DSState => {
    switch (action.action) {
        case 'PrevMonth': {
            const newMonth = subMonths(state.renderingDate, 1);
            return {...state, renderingDate: newMonth, month: renderMonth(newMonth)};
        }
        case 'NextMonth': {
            const newMonth = addMonths(state.renderingDate, 1);
            return {...state, renderingDate: newMonth, month: renderMonth(newMonth)};
        }
        case 'PrevYear': {
            const newYear = subYears(state.renderingDate, 1);
            return {...state, renderingDate: newYear, year: renderYear(newYear)};
        }
        case 'NextYear': {
            const newYear = addYears(state.renderingDate, 1);
            return {...state, renderingDate: newYear, year: renderYear(newYear)};
        }
        case 'SetView': {
            return {
                ...state,
                renderingDate: action.view.date,
                currentView: action.view,
                month: action.view.view === 'Day' ? renderMonth(action.view.date) : state.month,
                year: action.view.view === 'Month' ? renderYear(action.view.date) : state.year
            }
        }
        case 'UnsetDate': {
            return {
                ...state,
                selectedDate: undefined
            }
        }
        case 'SelectDate': {
            return {
                ...state,
                shown: false,
                selectedDate: new Date(
                    state.renderingDate.getUTCFullYear(),
                    state.renderingDate.getMonth(),
                    action.idx
                )
            }
        }
        case "SetDate": {
            return {
                ...state,
                shown: false,
                selectedDate: action.date
            }
        }
        case "Toggle": {
            const renderingDate = state.selectedDate ? state.selectedDate : new Date();
            return {
                ...state,
                renderingDate: renderingDate,
                month: renderMonth(renderingDate),
                shown: !state.shown,
                currentView: {view: 'Day', date: renderingDate}
            }
        }
        case "Close":
            return {...state, shown: false}
    }
}

export const useOnClickOutside = <T extends HTMLElement, >(onClickOutside: () => void, trigger = true) => {
    const ref = useRef<T>(null);
    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (ref.current &&
                e.target &&
                !ref.current.contains(e.target as Node)
            ) {
                onClickOutside();
            }
        };
        if (trigger) {
            document.addEventListener('mousedown', handleClick);
        } else {
            document.removeEventListener('mousedown', handleClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleClick);
        }
    }, [onClickOutside, trigger]);

    return ref;
}

export const DatePicker: FC<DatePickerProps> = ({value, onChange, className = ''}) => {
    const initialState: DSState = {
        selectedDate: undefined,
        renderingDate: value || new Date(),
        currentView: {date: value || new Date(), view: 'Month'},
        month: renderMonth(value || new Date()),
        year: renderYear(value || new Date()),
        shown: false
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    const onClickOutside = useCallback(() => {
        dispatch({action: 'Close'})
    }, []);
    const rref = useOnClickOutside<HTMLDivElement>(onClickOutside, state.shown);

    useEffect(() => {
        dispatch({action: 'SetDate', date: value})
    }, [value]);

    return <div className={`relative ${className}`} ref={rref}>
        <button className={ `rounded-lg ${state.shown ? 'bg-blue-400 text-white' : 'bg-gray-100 text-gray-600 info'} px-4 py-2 flex items-center text-sm w-full border-0` }
          onClick={_ => dispatch({action: 'Toggle'})}
        type="button">
            {state.selectedDate ?
                format(state.selectedDate, "dd 'de' LLLL 'de' yyyy", {locale: es})
                : 'Fecha'}
                <FontAwesomeIcon icon={faAngleDown} className="ml-2 mr-0"/>
        </button>
        {state.shown ?
            (<div className="datepicker-container">
                {state.currentView.view === 'Day' ?
                    <>
                        <div className="flex items-center h-6">
                            <button className="w-1/6 text-center cursor-pointer focus:outline-none"
                              onClick={_ => dispatch({action: 'PrevMonth'})}
                              type="button">
                                <FontAwesomeIcon icon={faAngleLeft}/>
                            </button>
                            <div className="w-4/6 text-center capitalize cursor-pointer"
                                 onClick={_ => dispatch({
                                     action: 'SetView',
                                     view: {view: 'Month', date: state.renderingDate}
                                 })}>
                                {format(state.renderingDate, "LLLL yyyy", {locale: es})}
                            </div>
                            <button className="w-1/6 text-center cursor-pointer focus:outline-none"
                              onClick={_ => dispatch({action: 'NextMonth'})}
                              type="button">
                                <FontAwesomeIcon icon={faAngleRight}/>
                            </button>
                        </div>
                        <div className="mt-2 datepicker-grid day">
                            {['D', 'L', 'M', 'M', 'J', 'V', 'S'].map((d, i) => (
                                <span className="font-bold" key={i}>{d}</span>
                            ))}
                            {state.month.map((d, i) => {
                                if (d) {
                                    return <span
                                        key={i}
                                        onClick={_ => {
                                            if (onChange) {
                                                onChange(new Date(state.renderingDate.getUTCFullYear(),
                                                    state.renderingDate.getUTCMonth(),
                                                    d));
                                            } else {
                                                dispatch({action: 'SelectDate', idx: d})
                                            }
                                        }}
                                        className={`date ${
                                            isSelected(state.renderingDate, d, state.selectedDate) ?
                                                'date-selected' : ''
                                        }`}>
                                            {d}
                                        </span>
                                } else {
                                    return <span key={i}/>
                                }
                            })}
                        </div>
                    </>
                    :
                    <>
                        <div className="flex items-center h-6">
                            <button className="w-1/6 text-center cursor-pointer focus:outline-none"
                              onClick={_ => dispatch({action: 'PrevYear'})}
                              type="button">
                                <FontAwesomeIcon icon={faAngleLeft}/>
                            </button>
                            <div className="w-4/6 text-center cursor-pointer">
                                {format(state.renderingDate, "yyyy", {locale: es})}
                            </div>
                            <button className="w-1/6 text-center cursor-pointer focus:outline-none"
                              onClick={_ => dispatch({action: 'NextYear'})}
                              type="button">
                                <FontAwesomeIcon icon={faAngleRight}/>
                            </button>
                        </div>
                        <div className="mt-2 datepicker-grid year">
                            {state.year.map((m, i) => (
                                <span key={i}
                                      className={`date text-sm capitalize ${isMonthSelected(state.renderingDate,
                                          m.getUTCMonth(),
                                          state.selectedDate) ? 'date-selected' : ''}`}
                                      onClick={_ => dispatch({action: 'SetView', view: {view: 'Day', date: m}})}
                                >{format(m, 'LLLL', {locale: es})}</span>
                            ))}
                        </div>
                    </>
                }
                <div className="flex items-center mt-2">
                    {state.selectedDate ?
                        <>
                            <button className="w-1/6 text-center text-gray-400 focus:outline-none hover:text-blue-500"
                                    onClick={_ => {
                                        if (onChange) {
                                            onChange(undefined)
                                        } else {
                                            dispatch({action: 'UnsetDate'});
                                        }
                                    }}
                                    type="button">
                                <FontAwesomeIcon icon={faEraser}/>
                            </button>
                            {/*
                            <button
                                className="self-stretch w-3/6 ml-auto mr-0 text-center text-white capitalize bg-blue-500 focus:outline-none rounded-md">
                                {format(state.selectedDate, 'LLLL', {locale: es})}
                            </button>
*/}
                        </>
                        : null
                    }
                </div>
            </div>)
            : null}
    </div>
}

import {faSearch, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import debounce from 'lodash.debounce';
import React, {Fragment, RefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {pgGET} from './networking';
import {useAppState} from './stores/AppStateStore';
import {strings} from './strings';
import {ProductoInventario} from './types';
import {usePopper} from "react-popper";
import {useAppNotifications} from "./uicomponents/Notifications";

export interface ProductoSearchProps {
  onSelectProducto?: (p: ProductoInventario) => void
  className?: string
  placeholder?: string
  ubicacion?: string
  disabled?: boolean
  onlyGranel?: boolean
  tipoProducto?: string
  onlyInStock?: boolean
}

const useProductoSearch = (search: string, ubicacion: string, onlyGranel?: boolean, tipoProducto?: string) => {
  const [{token}] = useAppState();
  const [results, setResults] = useState<ProductoInventario[] | undefined>(undefined);
  const [isSearching, setIsSearching] = useState(false);

  const doSearch = useMemo(() => debounce((s: string, u: string, ac: AbortController) => {
    setIsSearching(true);
    const params = [
      `or=(codigo.ilike.*${s}*,nombre.ilike.*${s}*)`,
      `and=(sucursal.eq.${u}${onlyGranel === undefined ? '' : onlyGranel ? ',is_granel.eq.true' : ',is_granel.eq.false'}${tipoProducto === undefined ? '' : `,tipo_producto.eq.${tipoProducto}`})`,
      `order=existencia.desc,nombre.asc`
    ];
    const st = `/api/productos_search_mvw?${params.join('&')}`;
    pgGET(st, token, {signal: ac.signal})
      .then(r => r.json())
      .then(res => {
        setResults(res);
        setIsSearching(false);
      })
      .catch(() => {
        // PASS
      });
  }, 200), [onlyGranel, tipoProducto, token]);

  useEffect(() => {
    const ac = new AbortController()
    if (search.length >= 3) {
      doSearch(search, ubicacion, ac);
    } else {
      setIsSearching(false);
      setResults(undefined);
    }

    return () => {
      ac.abort();
    }
  }, [search, doSearch, ubicacion]);

  return {doSearch, results, isSearching}
}

const useOnClickOutsidePS = (onClickOutside: () => void,
                             triggerRef: RefObject<HTMLElement>,
                             popperRef: RefObject<HTMLElement>,
                             trigger = true) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (triggerRef.current &&
        popperRef.current &&
        e.target &&
        (!triggerRef.current.contains(e.target as Node) &&
          !popperRef.current.contains(e.target as Node))
      ) {
        onClickOutside();
      }
    }
    if (trigger) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleClick);
    }
  }, [onClickOutside, triggerRef, popperRef, trigger]);
}

export const ProductoSearch = ({
                                 onSelectProducto,
                                 className,
                                 placeholder = 'Producto',
                                 ubicacion = '_global',
                                 disabled = false,
                                 onlyGranel = undefined,
                                 tipoProducto = undefined,
                                 onlyInStock = false
                               }: ProductoSearchProps) => {
  const [search, setSearch] = useState("");
  const {results, isSearching} = useProductoSearch(search, ubicacion, onlyGranel, tipoProducto);
  const referenceRef = useRef(null);
  const popperRef = useRef(null);
  const firstEltRef = useRef<HTMLLIElement>(null);
  const {styles, attributes} = usePopper(referenceRef.current,
    popperRef.current,
    {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 10]
          }
        }
      ]
    });

  const {addNotification} = useAppNotifications()

  useOnClickOutsidePS(() => {
    setSearch("")
  }, referenceRef, popperRef, search !== "");

  const withStockOnSelectProducto = useCallback((p: ProductoInventario) => {
    if (!onSelectProducto) return;
    if (onlyInStock) {
      if (p.existencia > 0) {
        onSelectProducto(p);
      } else {
        addNotification("No hay existencias", `No hay existencias de ${p.nombre} en la sucursal.`)
      }
    } else {
      onSelectProducto(p);
    }
  }, [addNotification, onSelectProducto, onlyInStock]);

  return <Fragment>
    <div className={`relative ventas-control ${className ?? ''}`}>
      {isSearching ?
        <FontAwesomeIcon icon={faSpinner} className="absolute text-sm text-gray-400 right-2 top-3 animate-spin"/>
        :
        <FontAwesomeIcon icon={faSearch} className="absolute text-sm text-gray-400 right-2 top-3"/>
      }
      <input type="text"
             value={search}
             onChange={e => setSearch(e.target.value)}
             className="w-full"
             placeholder={placeholder}
             disabled={disabled}
             ref={referenceRef}
      />
    </div>
    <div ref={popperRef} style={styles.popper} {...attributes.popper}
         className="ventas-control flex">
      <ul style={styles.offset}
          className="bg-white rounded-md shadow-md border-gray-200 w-full overflow-auto max-h-64">
        {results === undefined ?
          null :
          results.length === 0 ?
            <li className="p-2 text-gray-600 text-sm">
              No se encontraron resultados
            </li> :
            results.map((r, i) => (
              <li
                className="p-2 border-b border-gray-200 cursor-pointer hover:bg-pblue hover:text-white focus:text-white group focus:bg-pblue focus:outline-none relative rounded-md"
                title={strings.agregar}
                tabIndex={0}
                key={r.id}
                ref={i === 0 ? firstEltRef : undefined}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setSearch("");
                    withStockOnSelectProducto(r);
                  }
                }}
                onClick={_ => {
                  setSearch("");
                  withStockOnSelectProducto(r);
                }}>
                <div>{r.nombre}</div>
                <div
                  className="text-xs text-gray-600 flex justify-between group-hover:text-white group-focus:text-white">
                  <span>{r.codigo}</span>
                  {ubicacion && ubicacion !== '_global' ? <span>Existencia: {r.existencia}</span> : null}
                </div>
              </li>))
        }
      </ul>
    </div>
  </Fragment>
}

import {SimpleSinglePage} from "./uicomponents/Generic";
import {strings} from "./strings";
import React, {useCallback, useState} from "react";
import {DatePicker} from "./uicomponents/DatePicker";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {format, isBefore, parseISO} from "date-fns";
import {useAppNotifications} from "./uicomponents/Notifications";
import {Token} from "./types";
import {pgGET} from "./networking";
import {useAppState} from "./stores/AppStateStore";
import {saveAs} from 'file-saver';
import {getRangoFechasAsString, newExcelWorkbook} from "./ExcelHelpers";

const reportes = [
  "Ventas",
  "Notas Entrada Salida"
];

export const ReportesPage = () => {
  const [reporte, setReporte] = useState("Ventas");
  const [fechaInicio, setFechaInicio] = useState<Date | undefined>();
  const [fechaFin, setFechaFin] = useState<Date | undefined>();
  const {addNotification} = useAppNotifications();
  const [{token}] = useAppState();

  const downloadReport = useCallback(() => {
    if (fechaInicio === undefined) {
      addNotification("Error", strings.faltaFechaInicio)
      return;
    }
    if (fechaFin === undefined) {
      addNotification("Error", strings.faltaFechaFin)
      return;
    }
    if (isBefore(fechaFin, fechaInicio)) {
      addNotification("Error", strings.fechasInvalidas)
      return;
    }

    if (!token) {
      return;
    }

    switch (reporte) {
      case 'Ventas':
        createExcelVentasReport(fechaInicio, fechaFin, token);
        break;
      case 'Notas Entrada Salida':
        createExcelNotasESReport(fechaInicio, fechaFin, token);
        break;
    }

  }, [addNotification, fechaFin, fechaInicio, reporte, token]);

  return <SimpleSinglePage className="content-single">
    <h1 className="font-bold text-xl mb-4">{strings.reportes}</h1>
    <div className="flex space-x-4">
      <label className="ventas-control mb-0 mt-auto">
        <span>Reporte</span>
        <select onChange={e => setReporte(e.target.value)}
                value={reporte}
                className="st w-full">
          {reportes.map(r => <option key={r} value={r}>{r}</option>)}
        </select>
      </label>
      <label className="mb-0 mt-auto">
        <span>Fecha inicio</span>
        <DatePicker onChange={v => setFechaInicio(v)}
                    value={fechaInicio}
        />
      </label>
      <label className="mb-0 mt-auto">
        <span>Fecha fin</span>
        <DatePicker onChange={v => setFechaFin(v)}
                    value={fechaFin}/>
      </label>
      <button className="info mb-2 h-10 mt-auto" type="button"
              onClick={downloadReport}>
        Descargar
        <FontAwesomeIcon icon={faDownload} className="ml-2"/>
      </button>
    </div>
  </SimpleSinglePage>
}


const retrieveVentas = async (inicio: Date, fin: Date, token: Token) => {
  const inicioFormat = format(inicio, "yyyy-MM-dd");
  const finFormat = format(fin, "yyyy-MM-dd");
  const res = await pgGET(`/api/reporte_ventas_vw?fecha=gte.${inicioFormat}&fecha=lte.${finFormat}&order=id_venta`, token);
  const jsonRes: VentaData[] = await res.json();
  return jsonRes
}

const createExcelVentasReport = async (inicio: Date, fin: Date, token: Token) => {
  const wb = newExcelWorkbook();
  const rangoFechas = getRangoFechasAsString(inicio, fin);
  const data = await retrieveVentas(inicio, fin, token);
  const sheet = wb.addWorksheet(rangoFechas, {
    headerFooter: {
      firstHeader: `Reporte ventas: '${rangoFechas}'`
    }
  });

  sheet.columns = [
    {header: 'Id Venta', key: 'id_venta', width: 10},
    {header: strings.codigo, key: 'codigo', width: 10},
    {header: strings.fecha, key: 'fecha', width: 12},
    {header: strings.nombre, key: 'nombre', width: 20},
    {header: strings.producto, key: 'producto', width: 15},
    {header: strings.categoria, key: 'categoria', width: 15},
    {
      header: strings.precio_publico, key: 'precio_publico', width: 15,
      style: {numFmt: '$#,##0.00;-$#,##0.00'}
    },
    {header: strings.cantidad, key: 'cantidad', width: 10},
    {
      header: strings.costo_total, key: 'costo_total', width: 10,
      style: {numFmt: '$#,##0.00;-$#,##0.00'}
    },
    {
      header: strings.comision, key: 'comision', width: 10,
      style: {numFmt: '0.00%'}
    },
    {
      header: strings.monto_comision, key: 'monto_comision', width: 15,
      style: {numFmt: '$#,##0.00;-$#,##0.00'}
    },
    {
      header: strings.subtotal_comision, key: 'subtotal_comision', width: 15,
      style: {numFmt: '$#,##0.00;-$#,##0.00'}
    },
    {
      header: strings.descuento, key: 'descuento', width: 10,
      style: {numFmt: '0.00%'}
    },
    {
      header: strings.monto_descuento, key: 'monto_descuento', width: 10,
      style: {numFmt: '$#,##0.00;-$#,##0.00'}
    },
    {header: strings.formadepago, key: 'forma_pago', width: 15},
    {header: strings.ubicacion, key: 'ubicacion', width: 20},
    {header: strings.canaldeventa, key: 'canal_venta', width: 20},
    {
      header: strings.total, key: 'total', width: 10,
      style: {numFmt: '$#,##0.00;-$#,##0.00'}
    },
  ];
  sheet.getRow(1).eachCell(c => {
    c.border = {
      top: {style: 'thin'},
      left: {style: 'thin'},
      bottom: {style: 'thin'},
      right: {style: 'thin'}
    };
    c.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: '00b050'
      },
    }
    c.font = {
      name: 'Arial',
      color: {argb: 'FFFFFF'},
      bold: true
    }
    c.alignment = {
      vertical: 'middle',
      horizontal: 'center'
    }
  });

  sheet.getRow(1).height = 24;

  data.forEach(d => {
    sheet.addRow({
      ...d,
      fecha: parseISO(d.fecha),
    });
  });

  const xlsxBuffer = await wb.xlsx.writeBuffer();
  const blob = new Blob([xlsxBuffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
  saveAs(blob, `Reporte ventas ${rangoFechas}.xlsx`);
}


interface VentaData {
  id_venta: number
  codigo: string
  fecha: string
  nombre: string
  producto: string
  categoria: string
  precio_publico: number
  cantidad: number
  costo_total: number
  comision: number
  monto_comision: number
  subtotal_comision: number
  descuento: number
  monto_descuento: number
  forma_pago: string
  ubicacion: string
  canal_venta: string
  total: number
}

interface NotaESData {
  id_nota: number
  usuario: string
  fecha: string
  tipo_nota: string
  tipo_movimiento: string
  aprobado_por: string
  fecha_aprobacion: string
  origen: string
  destino: string
  descripcion: string
  codigo: string
  costo: number
  cantidad: number
  total_producto: string
}

const retrieveNotas = async (inicio: Date, fin: Date, token: Token) => {
  const inicioFormat = format(inicio, "yyyy-MM-dd");
  const finFormat = format(fin, "yyyy-MM-dd");
  const res = await pgGET(`/api/reporte_entradas_salidas_vw?fecha=gte.${inicioFormat}&fecha=lte.${finFormat}&order=id_nota`, token);
  const jsonRes: NotaESData[] = await res.json();
  return jsonRes
}

const createExcelNotasESReport = async (inicio: Date, fin: Date, token: Token) => {
  const wb = newExcelWorkbook();
  const rangoFechas = getRangoFechasAsString(inicio, fin);
  const data = await retrieveNotas(inicio, fin, token);

  const sheet = wb.addWorksheet(rangoFechas, {
    headerFooter: {
      firstHeader: `Reporte Notas Entrada/Salida: ${rangoFechas}`
    }
  });

  sheet.columns = [
    {header: 'Id Nota', key: 'id_nota', width: 10},
    {header: strings.nombre, key: 'usuario', width: 15},
    {header: strings.fecha, key: 'fecha', width: 15},
    {header: strings.tipo_nota, key: 'tipo_nota', width: 10},
    {header: strings.tipo_movimiento, key: 'tipo_movimiento', width: 18},
    {header: strings.aprobado_por, key: 'aprobado_por', width: 15},
    {header: strings.fecha_aprobacion, key: 'fecha_aprobacion', width: 15},
    {header: strings.origen, key: 'origen', width: 25},
    {header: strings.destino, key: 'destino', width: 25},
    {header: strings.descripcion, key: 'descripcion', width: 25},
    {header: strings.codigo, key: 'codigo', width: 12},
    {
      header: strings.costo, key: 'costo', width: 10,
      style: {numFmt: '$#,##0.00;-$#,##0.00'}
    },
    {header: strings.cantidad, key: 'cantidad', width: 10},
    {
      header: strings.total_producto, key: 'total_producto', width: 15,
      style: {numFmt: '$#,##0.00;-$#,##0.00'}
    },
  ];

  sheet.getRow(1).eachCell(c => {
    c.border = {
      top: {style: 'thin'},
      left: {style: 'thin'},
      bottom: {style: 'thin'},
      right: {style: 'thin'}
    };
    c.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: '00b050'
      },
    }
    c.font = {
      name: 'Arial',
      color: {argb: 'FFFFFF'},
      bold: true
    }
    c.alignment = {
      vertical: 'middle',
      horizontal: 'center'
    }
  });

  sheet.getRow(1).height = 24;

  data.forEach(d => {
    sheet.addRow({
      ...d,
      fecha: parseISO(d.fecha),
      ...{fecha_aprobacion: d.fecha_aprobacion ? parseISO(d.fecha_aprobacion) : null}
    });
  });

  const xlsxBuffer = await wb.xlsx.writeBuffer();
  const blob = new Blob([xlsxBuffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
  saveAs(blob, `Reporte notas entrada salida ${rangoFechas}.xlsx`);
}

import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useCatalog, usePermissions, useSimpleCRUD} from './hooks';
import {strings} from './strings';
import {ItemEditorModalForm, Producto, SelectOption} from './types';
import {SimpleHeader, SimpleItem, SimpleModalForm, SimpleSinglePage, UISelect} from './uicomponents/Generic';
import {Listing} from './uicomponents/Listing';
import swal from 'sweetalert';


interface ProductoEditable extends Omit<Producto, 'categoria_id' | 'tipo_producto_id'> {
  categoria_id: SelectOption
  tipo_producto_id: SelectOption
}

const ProductosHeader = () => (
  <SimpleHeader
    className="productos"
    labels={[strings.codigo, strings.nombre,
      strings.precio_publico, strings.precio_distribuidor, strings.precio_consignacion, strings.costo,
      strings.categoria]}
  />)

const ProductoItemMaker = (
  catCategoriaFn: (v: number) => string | undefined,
  onUpdate: (v: Producto) => void,
  onDelete: (id: number) => void
) => {
  return (i: Producto, canEdit?: boolean) => (
    <SimpleItem
      className="productos"
      values={[
        i.codigo,
        i.nombre,
        i.precio_publico?.toFixed(2) ?? 0,
        i.precio_distribuidor?.toFixed(2) ?? 0,
        i.precio_consignacion?.toFixed(2) ?? 0,
        i.costo?.toFixed(2) ?? 0,
        catCategoriaFn(i.categoria_id) ?? ''
      ]}
      canEdit={canEdit}
      onUpdate={() => onUpdate(i)}
      onDelete={() => onDelete(i.id!!)}
    />)
}

export const ProductosPage = () => {
  const [productos, {newItem, updateItem, deleteItem}, pagination] = useSimpleCRUD<Producto>('/api/productos')
  const [modalKind, setModalKind] = useState<'new' | 'update' | undefined>();
  const [, , catCategoriaFn] = useCatalog("cat_categorias");
  const [itemToUpdate, setUpdateItem] = useState<Producto | undefined>();
  const permissions = usePermissions();

  return <SimpleSinglePage className="content-single">
    <Listing
      items={productos}
      header={ProductosHeader}
      canCreate={permissions.productos.canCreate}
      canEdit={permissions.productos.canUpdate}
      itemView={ProductoItemMaker(catCategoriaFn, p => {
          setUpdateItem(p);
          setModalKind('update');
        },
        id => {
          swal({
            text: strings.seguroquedeseasborrar,
            buttons: ["Cancelar", true]
          }).then(v => {
            if (v) {
              deleteItem(id)
            }
          })
        })}
      title={strings.productos}
      onNew={() => {
        setUpdateItem(undefined);
        setModalKind('new');
      }}
      pagination={pagination}
    />
    {modalKind !== undefined ?
      <ProductoForm
        onSubmit={p => {
          if (modalKind === 'new') {
            newItem({
              ...p,
              categoria_id: Object(p.categoria_id).value,
              tipo_producto_id: Object(p.tipo_producto_id).value
            });
          } else if (modalKind === 'update') {
            updateItem(p.id!!, {
              ...p,
              categoria_id: Object(p.categoria_id).value,
              tipo_producto_id: Object(p.tipo_producto_id).value
            })
          }
        }}
        item={itemToUpdate}
        title={modalKind === 'new' ? 'Nuevo producto' : 'Actualizar producto'}
        isOpen={true}
        onRequestClose={() => {
          setModalKind(undefined);
          setUpdateItem(undefined)
        }}
      />
      : null
    }
  </SimpleSinglePage>
}

const ProductoForm: ItemEditorModalForm<Producto> =
  ({item, onSubmit, ...modalProps}) => {
    const {register, handleSubmit, control, formState, reset} =
      useForm<ProductoEditable>()
    const {errors} = formState
    const [, catLV, resolverFn] = useCatalog("cat_categorias");
    const [, catTipoProducto, catTPResolver] = useCatalog("cat_tipo_producto");
    const [catSelection, setCatSelection] = useState<SelectOption>();
    const [tpSelection, setTPSelection] = useState<SelectOption>();
    useEffect(() => {
      if (item) {
        setCatSelection({label: resolverFn(item?.categoria_id) ?? '', value: item?.categoria_id})
      } else {
        setCatSelection(undefined)
      }
    }, [item, resolverFn]);

    useEffect(() => {
      if (item && item.tipo_producto_id) {
        setTPSelection({label: catTPResolver(item?.tipo_producto_id) ?? '', value: item?.tipo_producto_id})
      } else {
        setTPSelection(undefined)
      }
    }, [item, catTPResolver])

    useEffect(() => {
      if (item) {
        const withSelect = {...item, categoria_id: catSelection, tipo_producto_id: tpSelection}
        reset(withSelect);
      }
    }, [catSelection, item, reset, tpSelection]);

    return <SimpleModalForm
      {...modalProps}
      formState={formState}
      reset={reset}
      onSubmit={handleSubmit(onSubmit)}>
      <label>
        <span>{strings.nombre}</span>
        <input type="text"
               {...register("nombre")}
               required
               className={errors.nombre && 'invalid'}
        />
      </label>
      <label>
        <span>{strings.codigo}</span>
        <input type="text"
               {...register("codigo")}
               required
               className={errors.codigo && 'invalid'}
        />
      </label>
      <label>
        <span>{strings.costo}</span>
        <input type="number"
               {...register("costo")}
               placeholder="0.00"
               min={0}
               step={0.01}
               required
               className={errors.costo && 'invalid'}
        />
      </label>
      <label>
        <span>{strings.precio_distribuidor}</span>
        <input type="number"
               {...register("precio_distribuidor")}
               placeholder="0.00"
               min={0}
               step={0.01}
               required
               className={errors.precio_distribuidor && 'invalid'}
        />
      </label>
      <label>
        <span>{strings.precio_publico}</span>
        <input type="number"
               {...register("precio_publico")}
               placeholder="0.00"
               min={0}
               step={0.01}
               required
               className={errors.precio_publico && 'invalid'}
        />
      </label>
      <label>
        <span>{strings.precio_consignacion}</span>
        <input type="number"
               {...register("precio_consignacion")}
               placeholder="0.00"
               min={0}
               step={0.01}
               required
               className={errors.precio_consignacion && 'invalid'}
        />
      </label>
      <label>
        <span>{strings.categoria}</span>
        <Controller
          name="categoria_id"
          control={control}
          rules={{required: true}}
          render={({field}) => (
            <UISelect {...field}
                      options={catLV}
                      className={errors.categoria_id && 'invalid'}
            />
          )}
        />
      </label>
      <label>
        <span>{strings.tipo_producto}</span>
        <Controller
          name="tipo_producto_id"
          control={control}
          rules={{required: true}}
          render={({field}) => (
            <UISelect {...field}
                      options={catTipoProducto}
                      className={errors.tipo_producto_id && 'invalid'}
            />
          )}
        />
      </label>
      <label>
        <span>{strings.capacidad}</span>
        <input type="number"
               {...register("capacidad")}
               required
               defaultValue="1"
               min={0}
               step={1}
        />
      </label>
      <label>
        <span>{strings.unidad}</span>
        <select {...register("unidad")} required defaultValue="P"
                className="w-full st">
          <option value="P">Pieza(s)</option>
          <option value="L">Litro(s)</option>
          <option value="ml">Mililitro(s)</option>
          <option value="kg">Kilogramo(s)</option>
          <option value="g">Gramo(s)</option>
        </select>
      </label>
      <label>
        <span>{strings.agranel}</span>
        <input type="checkbox"
               {...register("is_granel")}
        />
      </label>
    </SimpleModalForm>
  }

import {faAngleLeft, faAngleRight, faPlus, faSearch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Fragment, ReactNode, useState} from 'react';

export interface Search<T> {
  searchKey: keyof T
}

export interface Pagination {
  onNext: () => void
  onPrev: () => void
  showNext: boolean
  showPrev: boolean
  currentPage: number
}

export interface ListingProps<T> {
  items: T[]
  search?: Search<T>
  title: string
  header: () => ReactNode
  itemView: (item: T, canEdit?: boolean) => ReactNode
  onNew?: () => void
  pagination?: Pagination
  canEdit?: boolean
  canCreate?: boolean
}

export const Listing = <T,>(
  {items, search, title, header, itemView, onNew, pagination, canEdit, canCreate}:
    ListingProps<T>) => {
  const [filter, setFilter] = useState("");

  return <div className="flex flex-col w-full">
    <div className="flex items-center justify-end mb-4">
      <h1 className="ml-0 mr-auto text-xl font-bold text-rblack">{title}</h1>
      {search ?
        <div className="relative w-64 ml-4">
          <input type="text" className="w-full"
            style={{paddingRight: '2.5rem'}}
            onChange={e => setFilter(e.target.value)}
            value={filter} />
          <FontAwesomeIcon icon={faSearch}
            className="absolute text-gray-500 top-2 right-4" />
        </div>
        : null
      }
      {onNew && canCreate ? <button className="ml-4 info"
        title="Nuevo"
        onClick={onNew}>
        <FontAwesomeIcon icon={faPlus} />
      </button> : null}
    </div>
    <div>
      {header()}
      {items
        .filter(i => {
          if (filter.trim() === '') {
            return true;
          } else if (!search) {
            return true;
          } else {
            return (i[search.searchKey] as unknown as string)
              .toLowerCase()
              .includes(filter.toLowerCase())
          }
        })
        .map((item, i) => (
          <Fragment key={i}>
            {itemView(item, true)}
          </Fragment>))}
    </div>
    {pagination ?
      <PaginationControl {...pagination} />
      : null
    }
  </div>
}

export const PaginationControl = (pagination: Pagination) => {
  return <div className="flex items-center justify-end my-2">
    <button
      className="pagination left"
      onClick={pagination.onPrev}
      disabled={!pagination.showPrev}>
      <FontAwesomeIcon icon={faAngleLeft}/>
    </button>
    <span className="pagination current">
      {pagination.currentPage + 1}
    </span>
    <button
      className="pagination right"
      onClick={pagination.onNext}
      disabled={!pagination.showNext}>
      <FontAwesomeIcon icon={faAngleRight}/>
    </button>
  </div>
}

import React, {createContext, FC, useCallback, useContext} from 'react';
import {useImmer} from 'use-immer';
import {Profile, Token} from '../types';
import {useHistory} from "react-router-dom";

export interface AppState {
  token?: Token
  profile?: Profile
}

type AppContextT = [AppState, (t: Token, p: Profile) => void, () => void];

const AppContext = createContext<AppContextT>([{token: undefined, profile: undefined},
  () => {
  }, () => {
  }]);

export const AppContextProvider: FC = ({children}) => {
  const [state, dispatch] = useImmer<AppState>({token: undefined, profile: undefined});

  const login = useCallback((token: Token, profile: Profile) => {
    dispatch(d => {
      d.token = token;
      d.profile = profile;
    });
  }, [dispatch]);

  const history = useHistory();

  const logout = useCallback(() => {
    history.replace('/');
    dispatch(d => {
      d.token = undefined;
      d.profile = undefined;
    });
  }, [dispatch, history]);

  return (<AppContext.Provider value={[state, login, logout]}>
    {children}
  </AppContext.Provider>)
}

export const useAppState = () => useContext(AppContext);

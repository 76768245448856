import React, {useCallback, useEffect, useState} from 'react';
import {Redirect, Route, Switch, useParams, useRouteMatch} from 'react-router-dom';
import swal from 'sweetalert';
import {usePermissions, useSimpleCRUD} from './hooks';
import {strings} from './strings';
import {CreatableCatalog, Descuento, ItemEditorModalForm} from './types';
import {SimpleHeader, SimpleItem, SimpleModalForm, SimpleSinglePage} from './uicomponents/Generic';
import {Listing} from './uicomponents/Listing';
import {NavList} from './uicomponents/NavList';
import {useForm} from "react-hook-form";

interface Catalogo {
  id: number
  catalogo: string
  label: string
  url: string
}

const catalogos: Catalogo[] = [
  {id: 1, catalogo: '/api/cat_canales_venta', url: 'canalesdeventa', label: strings.canalesdeventa},
  {id: 2, catalogo: '/api/cat_tipos_cliente', url: 'tipocliente', label: strings.tipocliente},
  {id: 3, catalogo: '/api/cat_ubicaciones_producto', url: 'ubicacionproducto', label: strings.ubicacionproducto},
  {id: 4, catalogo: '/api/cat_descuentos', url: 'descuentos', label: strings.descuentos},
  {id: 5, catalogo: '/api/cat_categorias', url: 'categorias', label: strings.categorias},
  {id: 6, catalogo: '/api/cat_formas_pago', url: 'formapago', label: strings.formadepago},
  {id: 7, catalogo: '/api/cat_tipo_producto', url: 'tipoproducto', label: strings.tipo_producto}
]

export const CatalogosPage = () => {
  const match = useRouteMatch();

  return <div className="flex flex-col items-center content-single">
    <NavList items={catalogos}/>
    <Switch>
      <Route path={`${match.path}`} exact>
        <Redirect to={`${match.path}/canalesdeventa`}/>
      </Route>
      <Route path={`${match.path}/descuentos`}>
        <DescuentosPage/>
      </Route>
      <Route path={`${match.path}/:catalogo`}>
        <CatalogoPage/>
      </Route>
    </Switch>
  </div>
}

const CatalogoHeader = () => (<SimpleHeader className="crud-grid catalog odd:bg-azure" labels={["Nombre"]}/>
)

const CatalogoItem = (onUpdate: (v: CreatableCatalog) => void,
                      onDelete: (id: number) => void) =>
  (i: CreatableCatalog, canEdit?: boolean) => {
  return <SimpleItem className="catalog crud-grid odd:bg-azure"
                     values={[i.nombre]}
                     canEdit={canEdit}
                     onDelete={() => onDelete(i.id!!)}
                     onUpdate={() => onUpdate(i)}
  />
}

const CatalogoPage = () => {
  const {catalogo} = useParams<{ catalogo: string }>();
  const cat = catalogos.find(c => c.url === catalogo);
  if (!cat) throw Error(`No se encontro catalogo: '${catalogo}'`)
  const [items, {newItem, updateItem, deleteItem}, pagination]
    = useSimpleCRUD<CreatableCatalog>(cat.catalogo);

  const onDelete = useCallback((id) => {
    swal({
      text: strings.seguroquedeseasborrar,
      buttons: ["Cancelar", true]
    }).then(v => {
      if (v) {
        deleteItem(id)
      }
    });
  }, [deleteItem]);

  const onUpdate = useCallback(v => {
    swal({
      text: strings.actualizarCatalogo,
      content: {element: 'input', attributes: {value: v.nombre}},
      buttons: ["Cancelar", true]
    }).then(e => {
      if (e) {
        updateItem(v.id!!, {id: v.id!!, nombre: e})
      }
    })
  }, [updateItem]);

  const permissions = usePermissions();

  return <SimpleSinglePage>
    <Listing items={items}
             title={cat.label}
             header={CatalogoHeader}
             itemView={CatalogoItem(onUpdate, onDelete)}
             search={{searchKey: 'nombre'}}
             pagination={pagination}
             canCreate={permissions.catalogo.canCreate}
             canEdit={permissions.catalogo.canUpdate}
             onNew={() => {
               swal({
                 text: strings.nuevo,
                 content: {element: "input"},
                 buttons: ["Cancelar", true]
               })
                 .then((v: string) => {
                   if (v) {
                     newItem({nombre: v})
                   }
                 })
             }}
    />
  </SimpleSinglePage>
}

const DescuentosHeader = () => (
  <SimpleHeader labels={["Nombre", "Descuento/Comisión", "Habilitado"]} className="descuentos"/>
);

const DescuentoItem = (onUpdate: (v: Descuento) => void) => (
  i: Descuento, canEdit?: boolean) => (
  <SimpleItem
    className="descuentos"
    values={[i.nombre,
      `${i.descuento * 100}% (${i.descuento})`,
      i.is_habilitado ? 'Habilitado' : 'Deshabilitado']}
    onUpdate={() => onUpdate(i)}
    canEdit={canEdit}
  />
)

const DescuentosPage = () => {
  const [items, {newItem, updateItem}, pagination] =
    useSimpleCRUD<Descuento>('/api/cat_descuentos')
  const [modal, setModal] = useState<'new' | 'update' | undefined>();
  const [item, setItem] = useState<Descuento | undefined>();
  const permissions = usePermissions();

  return <SimpleSinglePage>
    <Listing items={items}
             search={{searchKey: 'nombre'}}
             pagination={pagination}
             title="Descuentos"
             header={DescuentosHeader}
             canCreate={permissions.catalogo.canCreate}
             canEdit={permissions.catalogo.canUpdate}
             itemView={DescuentoItem(d => {
               setItem(d)
               setModal('update');
             })}
             onNew={() => {
               setItem(undefined);
               setModal('new')
             }}
    />
    {
      modal !== undefined ?
        <DescuentoForm
          onSubmit={d => modal === 'new' ? newItem(d) : updateItem(d.id, d)}
          title={modal === 'new' ? 'Nuevo descuento' : 'Actualizar descuento'}
          isOpen={true}
          onRequestClose={() => {
            setModal(undefined);
          }}
          item={item}
        />
        : null
    }
  </SimpleSinglePage>
}

const DescuentoForm: ItemEditorModalForm<Descuento> =
  ({item, onSubmit, ...modalProps}) => {
    const {register, handleSubmit, formState, reset} =
      useForm<Descuento>();

    useEffect(() => {
      reset(item)
      return () => {
        reset();
      }
    }, [item, reset])
    return <SimpleModalForm
      {...modalProps}
      formState={formState}
      onSubmit={handleSubmit(onSubmit)}
      reset={reset}>
      <label>
        <span>{strings.nombre}</span>
        <input type="text"
               {...register("nombre")}
               required
        />
      </label>
      <label>
        <span>{strings.descuento}</span>
        <input type="number"
               {...register("descuento")}
               required
               min={-1}
               max={1}
               step={0.01}
        />
      </label>
      <label>
        <span>{strings.habilitado}</span>
        <input type="checkbox"
               {...register("is_habilitado")}
        />
      </label>
    </SimpleModalForm>
  }

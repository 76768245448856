import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {CatalogosPage} from './Catalogos';
import {ConfigPage} from './Config';
import {Login} from './Login';
import {PrivateRoute} from './Routes';
import {Header} from './uicomponents/Header';
import {VentasPage} from './Ventas';
import {setAppElement} from 'react-modal';
import {AppContextProvider, useAppState} from './stores/AppStateStore';
import {ProductosPage} from './Productos';
import {InventariosPage} from './Inventarios';
import {NotasPage} from './Notas';
import {ConversionesPage} from "./Conversiones";
import {Notifications} from "./uicomponents/Notifications";
import {ReportesPage} from "./Reportes";
import {Footer} from "./uicomponents/Footer";

setAppElement("#root");

function App() {
  return (
    <Router>
      <AppContextProvider>
        <Application/>
      </AppContextProvider>
    </Router>
  );
}

const CatchAll = () => {
  const [{profile}] = useAppState()
  const location = useLocation();
  if (!profile) return <Redirect to={`/login?p=${encodeURIComponent(location.pathname)}`}/>;

  // Try to redirect to the first route to which the user may have permissions
  if (profile.permissions.ventas.canView) return <Redirect to="/ventas"/>
  if (profile.permissions.catalogo.canView) return <Redirect to="/catalogos"/>
  if (profile.permissions.entradasalida.canView) return <Redirect to="/notas"/>
  if (profile.permissions.conversiones.canView) return <Redirect to="/conversiones"/>
  if (profile.permissions.reportes.canView) return <Redirect to="/reportes"/>
  if (profile.permissions.productos.canView) return <Redirect to="/productos"/>
  if (profile.permissions.inventario.canView) return <Redirect to="/inventarios"/>
  if (profile.permissions.configuracion.canView) return <Redirect to="/configuracion"/>

  return <div>No tiene permisos para acceder algun modulo.</div>
}

const Application = () => {
  const [{profile}] = useAppState()

  return <Notifications>
    <Switch>
      <Route path="/login"><Login/></Route>
      <div className="main-grid">
        {profile && <Header/>}
        <Route exact path="/redirect">
          <CatchAll/>
        </Route>
        <PrivateRoute redirect="/login"
                      path="/catalogos"
                      hasPermissions={profile?.permissions.catalogo.canView}
                      redirectOnNoPermissions="/">
          <CatalogosPage/>
        </PrivateRoute>
        <PrivateRoute redirect="/login"
                      path="/ventas"
                      hasPermissions={profile?.permissions.ventas.canView}
                      redirectOnNoPermissions="/redirect">
          <VentasPage/>
        </PrivateRoute>
        <PrivateRoute redirect="/login" path="/notas"
                      hasPermissions={profile?.permissions.entradasalida.canView}
                      redirectOnNoPermissions="/redirect">
          <NotasPage/>
        </PrivateRoute>
        <PrivateRoute redirect="/login" path="/conversiones"
                      hasPermissions={profile?.permissions.conversiones.canView}
                      redirectOnNoPermissions="/redirect">
          <ConversionesPage/>
        </PrivateRoute>
        <PrivateRoute redirect="/login" path="/reportes"
                      hasPermissions={profile?.permissions.reportes.canView}
                      redirectOnNoPermissions="/redirect">
          <ReportesPage/>
        </PrivateRoute>
        <PrivateRoute redirect="/login" path="/productos"
                      hasPermissions={profile?.permissions.productos.canView}
                      redirectOnNoPermissions="/redirect">
          <ProductosPage/>
        </PrivateRoute>
        <PrivateRoute redirect="/login" path="/inventarios"
                      hasPermissions={profile?.permissions.inventario.canView}
                      redirectOnNoPermissions="/redirect">
          <InventariosPage/>
        </PrivateRoute>
        <PrivateRoute redirect="/login" path="/configuracion"
                      hasPermissions={profile?.permissions.configuracion.canView}
                      redirectOnNoPermissions="/redirect">
          <ConfigPage/>
        </PrivateRoute>
        {profile && <Footer/>}
      </div>
    </Switch>
  </Notifications>
}

export default App;

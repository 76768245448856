import {format} from "date-fns";
import {Workbook} from "exceljs";

export const newExcelWorkbook = () => {
  const wb = new Workbook();
  wb.creator = 'Sistema Inventarios';
  wb.created = new Date();
  wb.calcProperties.fullCalcOnLoad = true;
  return wb;
}

export function getRangoFechasAsString(inicio: Date, fin: Date) {
  return `${format(inicio, "yyyy-MM-dd")}-${format(fin, "yyyy-MM-dd")}`;
}
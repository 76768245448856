import {faPen, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {ComponentProps, FormEvent, forwardRef, ReactNode, useEffect, useRef} from "react";
import {FormState} from "react-hook-form";
import ReactModal from 'react-modal';
import Select from "react-select";
import {strings} from "../strings";

interface SimpleSinglePageProps {
  children: ReactNode
  className?: string
}

export const SimpleSinglePage = ({children, className = ''}: SimpleSinglePageProps) => {
  return <div className={`flex flex-col w-full px-8 mt-4 ${className}`}>
    {children}
  </div>
}

const oddBgColor = "odd:bg-azure";
const hoverBgColor = "hover:bg-nyellow-light";

interface SimpleHeaderProps {
  className?: string
  labels: string[]
}

export const SimpleHeader = ({className = '', labels}: SimpleHeaderProps) => {
  return <div className={`py-4 font-bold crud-grid ${oddBgColor} ${className}`}>
    {labels.map((l, i) => (
      <div key={i}>{l}</div>
    ))}
  </div>
}

interface SimpleItemProps {
  className?: string
  values: (number | string)[]
  onUpdate?: () => void
  onDelete?: () => void
  canEdit?: boolean
}

export const SimpleItem = ({className = '', values, onUpdate, onDelete, canEdit}: SimpleItemProps) => (
  <div className={`py-2 crud-grid ${oddBgColor} ${hoverBgColor} ${className}`}>
    {values.map((v, i) => (<div key={i}>{v}</div>))}
    {
      onUpdate && canEdit ?
        <button onClick={onUpdate} className="inline-btn">
          <FontAwesomeIcon icon={faPen}
                           className="cursor-pointer text-pblue"
          />
        </button>
        : null
    }
    {
      onDelete && canEdit ?
        <button onClick={onDelete} className="inline-btn">
          <FontAwesomeIcon icon={faTimesCircle}
                           className="text-gray-400 cursor-pointer hover:text-red-500"/>
        </button>
        : null
    }
  </div>
)

ReactModal.defaultStyles = {}
export const SimpleModal = (props: ReactModal.Props & { children: ReactNode, title: string }) => {
  return <ReactModal
    {...props}>
    <div className="scroll-component">
      <div className="scroll-content">
        <div className="h-16 bg-pblue">
          <h3 className="p-4 text-lg font-bold text-white">{props.title}</h3>
        </div>
        <div className="p-4">
          {props.children}
        </div>
      </div>
    </div>
  </ReactModal>
}

export const SimpleModalForm = <T, >(props: ReactModal.Props &
  {
    children: ReactNode,
    title: string,
    formState: FormState<T>,
    onSubmit: (event: FormEvent<HTMLFormElement>) => void,
    reset: () => void
  }) => {
  const btnRef = useRef<HTMLButtonElement | null>(null)
  useEffect(() => {
    if (props.formState.isSubmitSuccessful && btnRef.current) {
      btnRef.current.click();
      props.reset()
    }
  }, [props, props.formState, props.reset]);

  return <SimpleModal {...props} onRequestClose={e => {
    props.onRequestClose && props.onRequestClose(e);
    props.reset();
  }}>
    <form
      className="grid grid-cols-2 gap-4"
      onSubmit={e => {
        props.onSubmit(e);
      }}>
      {props.children}
      <div className="flex items-end col-span-2">
        <input type="submit"
               value={strings.guardar}
               className="order-2 ml-auto mr-0 info"
               disabled={props.formState.isSubmitting}
        />
        <button onClick={e => {
          props.onRequestClose && props.onRequestClose(e);
        }}
                type="button"
                className="order-1 ml-0 mr-auto danger"
                ref={btnRef}
                disabled={props.formState.isSubmitting}>
          {strings.cancelar}
        </button>
      </div>
    </form>
  </SimpleModal>
}

export type UISelectProps = ComponentProps<typeof Select>;

export const UISelect = forwardRef<HTMLInputElement, UISelectProps>((props: UISelectProps, _ref) => {
  return <Select
    {...props}
    menuPortalTarget={document.body}
    className={`${props.className || ''} react-select`}
    classNamePrefix="rs"
    placeholder=""
    styles={{
      control: (base) => ({
        ...base,
        height: '38px',
        minHeight: '38px',
        borderRadius: '0.125rem',
        borderColor: 'rgba(229, 231, 235, 1)'
      }),
      valueContainer: (base) => ({
        ...base,
        height: '38px',
        padding: '0 6px'
      }),
      input: (css) => ({
        ...css,
        margin: '0px'
      }),
      indicatorSeparator: _ => ({
        display: 'none'
      }),
      indicatorsContainer: (base) => ({
        ...base,
        height: '38px'
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 9999
      })
    }}
  />
})

import React, {FC, ReactElement, useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useImmer} from 'use-immer';
import {useCatalog, useListingWithPagination, usePermissions} from './hooks';
import {ProductoSearch} from './ProductosSearch';
import {strings} from './strings';
import {InventarioSucursalEntry, ItemEditorModalFormProps, Producto, SelectOption, Token} from './types';
import {SimpleHeader, SimpleItem, SimpleModalForm, SimpleSinglePage, UISelect} from './uicomponents/Generic';
import {useAppState} from "./stores/AppStateStore";
import {pgDELETE, pgGET, pgPOST} from "./networking";
import swal from "sweetalert";
import {PaginationControl} from "./uicomponents/Listing";
import {faBarcode, faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {newExcelWorkbook} from "./ExcelHelpers";
import {format} from 'date-fns';
import {saveAs} from "file-saver";
import NewWindow from "react-new-window";
import {useBarcode} from "react-barcodes";

export const InventariosPage = () => {
  const [, ubicaciones] = useCatalog("cat_ubicaciones_producto");
  const [ubicacion, setUbicacion] = useState<SelectOption | null>(null)

  return <SimpleSinglePage className="flex flex-col content-single">
    <div className="flex items-center justify-end">
      <h1 className="text-xl font-bold mr-auto">Inventario</h1>
      <label className="ventas-control">
        <span>{strings.ubicacion}</span>
        <UISelect
          options={ubicaciones}
          value={ubicacion}
          onChange={(v: SelectOption) => setUbicacion(v)}
        />
      </label>
    </div>
    {
      ubicacion ?
        <InventarioListing ubicacion={ubicacion}/> :
        null
    }
  </SimpleSinglePage>
}

interface InventarioListingProp {
  ubicacion: SelectOption
}

const InventarioListing = ({ubicacion}: InventarioListingProp) => {
  const {
    invState: {items, editModalOpen, inventarioEntry, barcodeWindowOpen}, onProductoSelected, setInvState,
    updateInventario, updateSingle, onItemDelete, pagination, exportInventario,
    setBarcodeWindowOpen
  } =
    useInventarios(ubicacion.label, (typeof ubicacion.value === 'string') ? parseInt(ubicacion.value) : ubicacion.value);

  const permissions = usePermissions();

  return <div className="w-full mt-4">
    <div className="flex items-center justify-end">
      <h2 className="mr-auto text-lg font-bold">{ubicacion.label}</h2>
      <button type="button"
              className="info mr-4"
              title="Descargar código de barras"
              onClick={() => setBarcodeWindowOpen(true)}>
        <FontAwesomeIcon icon={faBarcode}/>
      </button>
      <button type="button"
              className="info mr-4"
              title="Descargar inventario"
              onClick={exportInventario}>
        <FontAwesomeIcon icon={faDownload}/>
      </button>
      {permissions.inventario.canCreate ?
        <ProductoSearch className="ventas-control"
                        onSelectProducto={onProductoSelected}
                        placeholder="Agregar o editar producto"
        />
        : null
      }
    </div>
    {
      barcodeWindowOpen &&
      <NewWindow onUnload={() => setBarcodeWindowOpen(false)}>
        <InventarioBarCodes items={items} sucursal={ubicacion.label}/>
      </NewWindow>
    }
    <div className="w-full mt-4">
      <SimpleHeader
        className="inventario"
        labels={[strings.codigo, strings.nombre,
          strings.precio_publico, strings.costo, strings.existencia,
          strings.stock_minimo, strings.stock_maximo, strings.categoria, '', '']}/>
      {
        items.map(i => (
          <SimpleItem className="inventario"
                      key={i.inventario_id}
                      values={[
                        i.codigo,
                        i.nombre,
                        i.precio_publico?.toFixed(2) ?? 0,
                        i.costo?.toFixed(2) ?? 0,
                        i.existencia,
                        i.stock_minimo,
                        i.stock_maximo,
                        i.categoria
                      ]}
                      onDelete={() => {
                        swal({
                          text: strings.seguroquedeseasborrar,
                          buttons: ["Cancelar", true]
                        }).then(v => {
                          if (v) {
                            onItemDelete(i.inventario_id)
                          }
                        })
                      }}
                      onUpdate={() => {
                        updateSingle(i)
                      }}
          />
        ))
      }
    </div>
    <PaginationControl currentPage={pagination.currentPage}
                       onPrev={pagination.prevPage}
                       onNext={pagination.nextPage}
                       showNext={pagination.hasNextPage}
                       showPrev={pagination.hasPrevPage}
    />
    <NewInventarioEntryForm
      onSubmit={updateInventario}
      title={strings.nuevo}
      item={inventarioEntry}
      ubicacion={ubicacion}
      onRequestClose={() => setInvState(d => {
        d.editModalOpen = false
      })}
      isOpen={editModalOpen}
    />
  </div>
}

export type InventarioNewEntryForm<T> = (props: ItemEditorModalFormProps<T> & ReactModal.Props & { ubicacion: SelectOption }) => ReactElement<any, any> | null

const NewInventarioEntryForm: InventarioNewEntryForm<NewInventarioEntry> =
  ({item, onSubmit, ubicacion, ...modalProps}) => {
    const {register, handleSubmit, formState, reset} = useForm<NewInventarioEntry>({defaultValues: item})
    const {errors} = formState;
    useEffect(() => {
      if (item) {
        reset(item)
      }
    }, [item, reset])

    return <SimpleModalForm
      {...modalProps}
      reset={reset}
      formState={formState}
      onSubmit={handleSubmit(onSubmit)}>

      <h3 className="font-bold text-lg col-span-2">
        {item?.producto_nombre}
      </h3>
      <h4 className="text-gray-600 text-sm col-span-2">
        {ubicacion.label}
      </h4>
      <input type="text"
             {...register("id")}
             className="hidden"
      />
      <input type="text"
             {...register("producto_id")}
             className="hidden"
      />
      <input type="text"
             {...register("ubicacion_id")}
             className="hidden"
      />
      <label>
        <span>{strings.existencia}</span>
        <input type="text"
               {...register("existencia")}
               required
               className={errors.existencia && 'invalid'}
        />
      </label>
      <label>
        <span>{strings.stock_maximo}</span>
        <input type="text"
               {...register("stock_maximo")}
               required
               className={errors.stock_maximo && 'invalid'}
        />
      </label>
      <label>
        <span>{strings.stock_minimo}</span>
        <input type="text"
               {...register("stock_minimo")}
               required
               className={errors.stock_minimo && 'invalid'}
        />
      </label>
    </SimpleModalForm>
  }

interface NewInventarioEntry {
  id?: number
  ubicacion_id?: number
  producto_id?: number
  stock_maximo: number
  stock_minimo: number
  existencia: number
  producto_nombre: string
}

interface InventarioState {
  items: InventarioSucursalEntry[]
  editModalOpen: boolean
  inventarioEntry?: NewInventarioEntry
  ubicacion_id?: number
  loadingProducto: boolean
  barcodeWindowOpen: boolean
}

const inventarioState = (): InventarioState => ({
  items: [],
  editModalOpen: false,
  loadingProducto: false,
  barcodeWindowOpen: false
})

const useInventarios = (ubicacion: string, ubicacion_id: number) => {
  const [invState, setInvState] = useImmer(inventarioState());
  const [{token}] = useAppState();

  const params = {
    order: 'nombre.desc',
    sucursal: `eq.${ubicacion}`
  };

  const {items: serverItems, ...pagination} =
    useListingWithPagination<InventarioSucursalEntry>('/api/inventarios_sucursal_mvw', params);

  useEffect(() => {
    setInvState(d => {
      d.items = serverItems
    });
  }, [serverItems, setInvState])

  const onProductoSelected = useCallback((p: Producto) => {
    setInvState(d => {
      d.loadingProducto = true;
    });

    pgGET(`/api/inventarios?ubicacion_id=eq.${ubicacion_id}&producto_id=eq.${p.id}`, token)
      .then(r => r.json())
      .then(r => {
        if (r.length > 0) {
          setInvState(d => {
            d.loadingProducto = false;
            d.inventarioEntry = {
              ...r[0],
              producto_nombre: p.nombre
            };
            d.editModalOpen = true;
          })
        } else {
          setInvState(d => {
            d.loadingProducto = false;
            d.inventarioEntry = {
              producto_nombre: p.nombre,
              ubicacion_id: ubicacion_id,
              producto_id: p.id,
              existencia: 0,
              stock_maximo: 100,
              stock_minimo: 0
            };
            d.editModalOpen = true;
          })
        }
      })
  }, [setInvState, ubicacion_id, token]);

  const updateInventario = useCallback((item: NewInventarioEntry) => {
    const {producto_nombre, ...payload} = item;
    if (payload.id === undefined || isNaN(parseInt(payload.id as unknown as string))) {
      delete payload.id
    }

    if (payload.ubicacion_id === undefined || isNaN(parseInt(payload.ubicacion_id as unknown as string))) {
      delete payload.ubicacion_id
    }

    if (payload.producto_id === undefined || isNaN(parseInt(payload.producto_id as unknown as string))) {
      delete payload.producto_id
    }

    // PostgREST UPSERT
    pgPOST('/api/inventarios', JSON.stringify(payload), token,
      {headers: {'Prefer': 'resolution=merge-duplicates,return=representation'}})
      .then(r => r.json()) // Regresa objeto insertado en tabla inventarios
      // id de objeto inventarios es inventario_id en inventarios_sucursal_mvw
      .then(r => pgGET(`/api/inventarios_sucursal_mvw?inventario_id=eq.${r[0].id}`, token))
      .then(r => r.json()) // Obtiene objeto enriquecido de vista inventarios_sucursal_mvw
      .then(r => setInvState(d => {
        const ie = r[0] as InventarioSucursalEntry;
        for (let i = 0; i < d.items.length; i++) {
          if (d.items[i].inventario_id === ie.inventario_id) {
            d.items[i] = ie;
            return
          }
        }
        d.items.unshift(ie)
      }));

  }, [setInvState, token]);

  const onItemDelete = useCallback((id: number) => {
    pgDELETE(`/api/inventarios?id=eq.${id}`, token)
      .then(() => {
        setInvState(d => {
          const idx = d.items.findIndex(i => i.inventario_id === id);
          d.items.splice(idx, 1);
        })
      });
  }, [setInvState, token]);

  const updateSingle = useCallback((ie: InventarioSucursalEntry) => {
    setInvState(d => {
      d.editModalOpen = true;
      d.inventarioEntry = {
        producto_nombre: ie.nombre,
        stock_minimo: ie.stock_minimo,
        stock_maximo: ie.stock_maximo,
        existencia: ie.existencia,
        ubicacion_id: undefined,
        producto_id: undefined,
        id: ie.producto_id
      }
    })
  }, [setInvState]);

  const exportInventario = useCallback(() => {
    exportInventarioAsXLSX(ubicacion, ubicacion_id, token!!);
  }, [ubicacion, ubicacion_id, token]);

  const setBarcodeWindowOpen = (isOpen: boolean) => {
    setInvState(d => {
      d.barcodeWindowOpen = isOpen;
    })
  }

  return {
    invState,
    setInvState,
    pagination,
    onProductoSelected,
    updateInventario,
    updateSingle,
    onItemDelete,
    exportInventario,
    setBarcodeWindowOpen
  }
}


const Barcode = ({value, nombre}: { value: string, nombre: string }) => {
  const {inputRef} = useBarcode({
    value: value,
  })

  return <>
    <svg ref={inputRef} className="print"/>
    <div className="text-sm font-bold font-mono text-center">{nombre}</div>
  </>
}

interface InventarioBarCodesProps {
  items: InventarioSucursalEntry[]
  sucursal: string
}

const InventarioBarCodes: FC<InventarioBarCodesProps> = ({items, sucursal}) => {
  const [chunked, setChunks] = useState<InventarioSucursalEntry[][]>([]);
  useEffect(() => {
    const chunks: InventarioSucursalEntry[][] = [];
    let mChunk: InventarioSucursalEntry[] = [];
    for (let i = 0; i < items.length; i++) {
      if (i % 3 === 0) {
        chunks.push(mChunk);
        mChunk = []
      }
      mChunk.push(items[i])
    }
    setChunks(chunks);
  }, [items])

  return <div className="container mx-auto">
    <div className="flex my-4 px-4 items-center print">
      <h2 className="font-bold text-xl mr-2">{sucursal}</h2>
      <span>{format(new Date(), 'yyyy-MM-dd')}</span>
      <span className="mr-0 ml-auto">Ctrl-P para imprimir</span>
    </div>
    <table>
      <tbody>
      {chunked.map((tr, i) => (
        <tr key={i}>
          {tr.map(i => (<td key={i.codigo} className="print">
            <Barcode value={i.codigo} nombre={i.nombre}/></td>))}
        </tr>
      ))}
      <tr>
      </tr>
      </tbody>

    </table>
  </div>
}

const retrieveInventario = async (ubicacionId: number, token: Token) => {
  const res = await pgGET(`/api/inventarios_sucursal_mvw?ubicacion_id=eq.${ubicacionId}&order=inventario_id.desc`, token)
  const jsonRes: InventarioSucursalEntry[] = await res.json();
  return jsonRes;
}

const exportInventarioAsXLSX = async (ubicacion: string, ubicacionId: number, token: Token) => {
  const wb = newExcelWorkbook();
  const data = await retrieveInventario(ubicacionId, token);
  const fecha = format(new Date(), "yyyy-MM-dd");
  const sheet = wb.addWorksheet(`Inventario ${ubicacion} - ${fecha}`,
    {
      headerFooter: {
        firstHeader: `Inventario ${ubicacion} - ${fecha}`
      }
    });

  sheet.columns = [
    {header: 'Id inventario', key: 'inventario_id', width: 10},
    {header: strings.sucursal, key: 'sucursal', width: 20},
    {header: strings.codigo, key: 'codigo', width: 10},
    {header: strings.nombre, key: 'nombre', width: 30},
    {
      header: strings.precio_publico, key: 'precio_publico', width: 12,
      style: {numFmt: '$#,##0.00;-$#,##0.00'}
    },
    {
      header: strings.costo, key: 'costo', width: 12,
      style: {numFmt: '$#,##0.00;-$#,##0.00'}
    },
    {header: strings.productogranel, key: 'is_granel', width: 15},
    {header: strings.capacidad, key: 'capacidad', width: 10},
    {header: strings.unidad, key: 'unidad', width: 10},
    {header: strings.existencia, key: 'existencia', width: 10},
    {header: strings.stock_minimo, key: 'stock_minimo', width: 15},
    {header: strings.stock_maximo, key: 'stock_maximo', width: 15},
    {header: strings.categoria, key: 'categoria', width: 15},
    {header: strings.tipo_producto, key: 'tipo_producto', width: 15},
  ];

  sheet.getRow(1).eachCell(c => {
    c.border = {
      top: {style: 'thin'},
      left: {style: 'thin'},
      bottom: {style: 'thin'},
      right: {style: 'thin'}
    };
    c.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: '00b050'
      },
    }
    c.font = {
      name: 'Arial',
      color: {argb: 'FFFFFF'},
      bold: true
    }
    c.alignment = {
      vertical: 'middle',
      horizontal: 'center'
    }
  });

  sheet.getRow(1).height = 24;

  data.forEach(d => {
    sheet.addRow(d);
  });

  const xlsxBuffer = await wb.xlsx.writeBuffer();
  const blob = new Blob([xlsxBuffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
  saveAs(blob, `Inventario ${ubicacion} - ${fecha}.xlsx`);
}
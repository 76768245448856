import React, {FC} from 'react';
import {Redirect, Route, RouteProps, useLocation} from 'react-router-dom';
import {useAppState} from './stores/AppStateStore';

interface PrivateRouteProps extends Omit<RouteProps, 'children'> {
  redirect: string
  hasPermissions: boolean | undefined
  redirectOnNoPermissions: string
}

export const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  const [appState] = useAppState()
  const location = useLocation();

  // If no token, redirect most likely to login
  if (!appState.token)
    return <Redirect to={`${props.redirect}?p=${encodeURIComponent(location.pathname)}`}/>;

  return <Route {...props}>
    {props.hasPermissions ?
      // if user has Permissions, then show route
      props.children
      :
      // Otherwise redirect to `redirectOnNoPermissions` without encoding the current path
      <Redirect to={`${props.redirectOnNoPermissions}`}/>}
  </Route>
}

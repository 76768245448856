import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {useAppState} from './stores/AppStateStore';
import {strings} from './strings';
import {pgGET} from "./networking";

export const Login = () => {
  const [v, setV] = useState({_username: '', pass: ''});
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(undefined);
  const [appState, login] = useAppState();
  const {search} = useLocation();
  const [redirect, setRedirect] = useState<string | undefined>()

  useEffect(() => {
    const params = new URLSearchParams(decodeURIComponent(search));
    const path = params.get('p')?.trim();
    if (path && path.startsWith("/")) {
      setRedirect(path);
    } else {
      setRedirect("/");
    }
  }, [redirect, search])

  useEffect(() => {
    if (!sent) return;
    setError(undefined);
    fetch('/api/rpc/login',
      {
        body: JSON.stringify(v),
        headers: {'Content-type': 'application/json'},
        method: 'POST'
      }
    ).then(res => {
      if (res.status > 199 && res.status < 300) {
        return res.json();
      } else {
        throw new Error('Usuario o contraseña invalida');
      }
    })
      .then(res => {
        const token = res[0];
        return pgGET("/api/rpc/inv_whoami", token)
          .then(r => r.json())
          .then(profile => {
            if (!profile) {
              throw new Error('No se encontro perfil')
            }
            login(token, profile);
          });
      })
      .catch(err => {
        setError(err.message);
        setSent(false);
      })
  }, [v, sent, login]);

  return (appState.token ?
    (redirect ?
      <Redirect to={redirect}/>
      : null) :
    <div className="login-container">
      <form className="bg-gray-200 w-72 rounded-md content"
            style={{placeSelf: 'center'}}
            onSubmit={e => {
              e.preventDefault();
              setSent(true);
            }}>
        <h1 className="h-12 p-2 text-lg font-bold text-center border-b border-gray-500 rounded-t-md">
          Iniciar sesión
        </h1>
        <div className="w-full login-grid">
          <label htmlFor="usuario">{strings.usuario}</label>
          <input id="usuario" type="text" name="usuario"
                 value={v._username}
                 onChange={e => {
                   const usuario = e.target.value;
                   setV(s => ({...s, _username: usuario}))
                 }}
          />
          <label htmlFor="password">{strings.password}</label>
          <input id="password" type="password" name="password"
                 value={v.pass}
                 onChange={e => {
                   const pass = e.target.value;
                   setV(s => ({...s, pass: pass}))
                 }}
          />
          {error ? <span className="text-xs text-red-500 col-span-2">{error}</span> : null}
          <div className="flex col-span-2">
            <button className="ml-auto mr-0 info" type="submit" disabled={sent}>Entrar
              {
                sent ?
                  <FontAwesomeIcon className="ml-3 animate-spin" icon={faSpinner}/>
                  : null
              }
            </button>
          </div>
        </div>
      </form>
    </div>)
}

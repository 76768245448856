import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {SimpleHeader, SimpleItem, SimpleSinglePage, UISelect} from "./uicomponents/Generic";
import {strings} from "./strings";
import {useCatalog, useListingWithPagination, usePermissions} from "./hooks";
import {Control, Controller, useForm, useWatch} from "react-hook-form";
import {InventarioSucursalEntry, Producto, ProductoInventario, SelectOption} from "./types";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ProductoSearch} from "./ProductosSearch";
import swal from "sweetalert";
import {NavList} from "./uicomponents/NavList";
import {Redirect, Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import {Listing, PaginationControl} from "./uicomponents/Listing";
import {
  faChevronLeft,
  faFilter,
  faMinusCircle,
  faPlus,
  faPlusCircle,
  faSpinner,
  faTimes,
  faUndo
} from "@fortawesome/free-solid-svg-icons";
import {useImmer} from "use-immer";
import {useAppState} from "./stores/AppStateStore";
import {pgGET, pgPOST} from "./networking";
import {toJSONOrToErr, useCatchAndNotify} from "./ErrorHandling";
import {createUnit, multiply, unit, Unit} from 'mathjs'

export const ConversionesPage = () => {
  const match = useRouteMatch();
  const permissions = usePermissions();

  const opciones = [
    {url: 'pasadas', label: 'Conversiones'},
    {url: 'nueva', label: 'Nueva', canShow: permissions.conversiones.canCreate}
  ];

  return <div className="flex flex-col items-center content-single">
    <NavList items={opciones}/>
    <Switch>
      <Route path={match.path} exact><Redirect to={`${match.path}/pasadas`}/></Route>
      <Route path={`${match.path}/pasadas`}><ConversionesPasadas/></Route>
      <Route path={`${match.path}/nueva`}><NuevaConversion/></Route>
    </Switch>
  </div>
}

interface Conversion {
  id: number
  ubicacion: string
  fuente: string
  destino: string
  cantidad: number
}

const ConversionesPasadas = () => {
  const {items, ...pagination} =
    useListingWithPagination<Conversion>('/api/conversiones_vw',
      {order: 'ubicacion.desc'})
  return (<SimpleSinglePage>
    <h1 className="text-xl font-bold">{strings.conversiones}</h1>
    <div>
      <SimpleHeader labels={[strings.ubicacion,
        strings.productogranel,
        strings.producto,
        strings.cantidad]}
                    className="conversiones"
      />
      {
        items.map(i => (
          <SimpleItem
            key={i.id}
            className="conversiones"
            values={[i.ubicacion, i.fuente, i.destino, i.cantidad]}/>
        ))
      }
    </div>
    <PaginationControl onNext={pagination.nextPage}
                       onPrev={pagination.prevPage}
                       showNext={pagination.hasPrevPage}
                       showPrev={pagination.hasNextPage}
                       currentPage={pagination.currentPage}/>

  </SimpleSinglePage>)
}

interface ConversionForm {
  ubicacion: SelectOption | null
  fuente: InventarioSucursalEntry | undefined
  destino: Producto
  cantidad: number
}

const NuevaConversion = () => {
  const {ubicaciones} = useNewConversion();
  const {handleSubmit, control, formState, reset} = useForm<ConversionForm>()
  const {errors} = formState;
  const [{token}] = useAppState();
  const history = useHistory();
  const [fuente, setFuente] = useState<InventarioSucursalEntry | undefined>(undefined);
  const [conversiones, setConversiones] = useImmer<ConversionTarget[]>([]);
  const handleError = useCatchAndNotify();

  useEffect(() => {
    // Try to load units if they're not already.
    try {
      createUnit('P');
    } catch {}
  }, []);

  const save = useCallback((cf: ConversionForm) => {
    if (!fuente || !cf.ubicacion) return
    reset();
    setConversiones([]);
    setFuente(undefined);
    const payload = {
      ubicacion_id: cf.ubicacion?.value,
      producto_fuente_inventario_id: fuente.inventario_id, // id de inventario en sucursal
      producto_fuente_producto_id: fuente.producto_id, // id de producto
      productos: conversiones
        .filter(c => !!c.producto)
        .map(c => ({
          producto_id: c.producto?.id,
          cantidad: c.cantidad
        }))
    }
    pgPOST('/api/rpc/do_conversion', JSON.stringify(payload), token,
      {headers: {'Prefer': 'params=single-object'}})
      .then(toJSONOrToErr)
      .then(() => {
        history.push(`/conversiones/pasadas`);
      })
      .catch(handleError)
  }, [conversiones, fuente, handleError, history, reset, setConversiones, token]);

  const fuenteCU = useMemo(() => {
    if (!fuente) return undefined;
    return unit(fuente.capacidad, fuente.unidad)
  }, [fuente]);

  const utilizadoCU = useMemo(() => {
    if (!fuente) return 0;
    return conversiones.reduce((acc, val) => {
      if (!val.unit) return acc;

      return acc + (multiply(val.unit, val.cantidad) as unknown as Unit).toNumber(fuente.unidad)
    }, 0);
  }, [conversiones, fuente])

  const disponible = useMemo(() => {
    if (!fuente) return 0;
    return fuente.capacidad - utilizadoCU;
  }, [fuente, utilizadoCU])

  const addConversion = useCallback((c: ConversionTarget) => {
    if (!fuente || !fuenteCU || !c.unit || !c.producto) return;
    if (!fuenteCU.equalBase(c.unit) && c.factor) {
      try {
        createUnit(getUnit(c.producto), `${c.factor}${fuente.unidad}`)
      } catch {
      }
      c.unit = unit(`${c.producto.capacidad}${getUnit(c.producto)}`)
    }

    setConversiones(d => {
      d.push(c);
    });
  }, [fuente, fuenteCU, setConversiones]);

  const addAmount = useCallback((ct: ConversionTarget) => {
    setConversiones(d => {
      const tct = d.find(dct => dct._id === ct._id);
      if (tct) {
        tct.cantidad++;
      }
    })
  }, [setConversiones]);

  const remAmount = useCallback((ct: ConversionTarget) => {
    if (ct.cantidad <= 0) return;

    setConversiones(d => {
      const tct = d.find(dct => dct._id === ct._id);
      if (tct) {
        tct.cantidad--;
      }
    })
  }, [setConversiones]);

  const removeConversion = useCallback((ct: ConversionTarget) => {
    setConversiones(d => {
      const idx = d.findIndex(dct => dct._id === ct._id);
      if (idx >= 0) {
        d.splice(idx, 1);
      }
    })
  }, [setConversiones]);

  return (<SimpleSinglePage className="content-single">
    <h1 className="text-xl font-bold">{strings.nuevaconversion}</h1>
    <form onSubmit={handleSubmit(save)}>
      <div className="flex justify-between">
        <label className="ventas-control">
          <span>{strings.ubicacion}</span>
          <Controller
            name="ubicacion"
            control={control}
            rules={{required: true}}
            render={({field}) => (
              <UISelect {...field}
                        options={ubicaciones}
                        className={errors && 'invalid'}/>
            )}
          />
        </label>
      </div>
      <ProductosGranelPorUbicacion control={control} onGranelSelect={p => setFuente(p)}/>
      {
        fuente ?
          <Fragment>
            <div className="text-sm font-bold">Utilizando {fuente.nombre} para conversiones.
            </div>
            <div>
              <span className="font-bold">Usando</span> {utilizadoCU.toFixed(3)}{fuente?.unidad ?? ''}.
            </div>
            <div>
              <span
                className="font-bold">Disponible</span> {disponible.toFixed(3)}{fuente?.unidad ?? ''}
            </div>
            <NewConversionWidget fuente={fuente} onNewConversion={addConversion}/>
          </Fragment>
          : null
      }
      <Listing
        items={conversiones}
        title="Conversiones"
        header={ConversionesListHeader}
        itemView={(c: ConversionTarget) => {
          return <div className="py-2 crud-grid odd:bg-azure hover:bg-nyellow-light conversion-target">
            <div>{c.producto?.nombre ?? ''}</div>

            <div className="flex items-center justify-center w-full">
              <FontAwesomeIcon icon={faMinusCircle}
                               onClick={() => remAmount(c)}
                               className="text-sm text-gray-900 cursor-pointer hover:text-red-500"/>
              <div className="w-1/2">{c.cantidad}</div>
              <FontAwesomeIcon icon={faPlusCircle}
                               className="text-sm text-gray-900 cursor-pointer hover:text-pblue"
                               onClick={() => addAmount(c)}
              />
            </div>
            <div>
              {c.unit && c.producto && fuente ?
                `${(multiply(c.unit, c.cantidad) as unknown as Unit).format({notation: 'fixed', precision: 2})}/
                ${((multiply(c.unit, c.cantidad) as unknown as Unit).toNumber(fuente.unidad) ?? 0).toFixed(3)}
                ${fuente.unidad}`
                : null
              }
            </div>
            <FontAwesomeIcon icon={faTimes}
                             className="cursor-pointer hover:text-red-500"
                             role="button"
                             onClick={() => removeConversion(c)}/>
          </div>
        }}
      />
      <div className="flex justify-around w-full py-4">
        <button className="danger"
                tabIndex={-1}
                type="button"
                onClick={() => {
                  swal({
                    text: strings.seperderanlosdatos,
                    buttons: ["Regresar", true]
                  }).then(v => {
                    if (v) {
                      setConversiones([]);
                    }
                  })
                }}
        >{strings.cancelar}<FontAwesomeIcon icon={faUndo} className="ml-2"/></button>
        <button className="info"
                type="submit"
                disabled={utilizadoCU > (fuente?.capacidad ?? Infinity) || conversiones.length === 0}
        >{strings.guardar}</button>
      </div>
    </form>
  </SimpleSinglePage>);
};

const useNewConversion = () => {
  const [, ubicaciones, ubicacionResolver] = useCatalog("cat_ubicaciones_producto");
  return {
    ubicaciones,
    ubicacionResolver
  }
}

interface ConversionTarget {
  _id: number
  cantidad: number
  producto: ProductoInventario | undefined
  factor: number
  unit?: Unit
}

interface ConversionWidgetProps {
  onNewConversion: (c: ConversionTarget) => void
  fuente: InventarioSucursalEntry
}

function getUnit(producto: ProductoInventario) {
  const normalized = producto.tipo_producto.replace(/[^a-zA-Z]/g, '')
  return producto.unidad + normalized
}

let _conversion_id = 0;

const NewConversionWidget = ({fuente, onNewConversion}: ConversionWidgetProps) => {
  const [state, setState] = useImmer<ConversionTarget>(() => ({
    _id: _conversion_id++,
    cantidad: 0,
    producto: undefined,
    factor: 0,
    unit: undefined
  }));

  const fuenteCU = useMemo(() => {
    if (!fuente) return undefined;
    return unit(fuente.capacidad, fuente.unidad)
  }, [fuente]);

  const destinoCU = useMemo(() => {
    const producto = state.producto;
    if (!producto || !fuenteCU) return undefined;
    const base = unit(producto.capacidad, producto.unidad);
    if (base.equalBase(fuenteCU))
      return unit(producto.capacidad, producto.unidad);

    try {
      const complex = unit(producto.capacidad, getUnit(producto))
      if (fuenteCU.equalBase(complex)) {
        return complex;
      }
    } catch {
      return base;
    }
    return base;
  }, [fuenteCU, state.producto])

  useEffect(() => {
    setState(d => {
      d.unit = destinoCU;
    })
  }, [destinoCU, setState]);

  const addConversion = useCallback(() => {
    if (state.producto &&
      state.cantidad &&
      ((destinoCU &&
        fuenteCU &&
        destinoCU.equalBase(fuenteCU)) ||
        state.factor)) {
      onNewConversion({...state});
      setState(d => {
        d._id = _conversion_id++;
        d.factor = 0;
        d.unit = undefined;
        d.producto = undefined;
        d.cantidad = 0
      })
    }
  }, [destinoCU, fuenteCU, onNewConversion, setState, state]);

  return <div className="flex mb-4 space-x-4">
    <label className="ventas-control">
      <span>{strings.producto}</span>
      {
        state.producto ?
          <span className="font-bold">{state.producto.nombre}</span>
          : <ProductoSearch
            tipoProducto={fuente.tipo_producto}
            onlyGranel={false}
            onSelectProducto={p => {
              setState(d => {
                d.producto = p;
              })
            }}
          />
      }
    </label>
    <label className="small-control">
      <span>{strings.cantidad}</span>
      <input type="number"
             className="w-full"
             value={isNaN(state.cantidad) ? '' : state.cantidad}
             onChange={e => setState(d => {
               d.cantidad = e.target.valueAsNumber;
             })}
             required
             step={1}
             min={0}/>
    </label>
    {state.producto && destinoCU && fuenteCU && !destinoCU.equalBase(fuenteCU) ?
      <label className="ventas-control">
        <span>Equivalencia de 1{state.producto.unidad} de {state.producto.tipo_producto} a 1{fuente.unidad} de {fuente.tipo_producto}</span>
        <input type="number"
               value={isNaN(state.factor) ? '' : state.factor}
               onChange={e => setState(d => {
                 d.factor = e.target.valueAsNumber;
               })}
               step={0.0000000001}
               min={0}
        />
      </label>
      : null
    }
    {
      state.producto ?
        <button type="button"
                className="mt-auto mb-2 ml-auto mr-2 danger"
                onClick={() => setState(d => {
                  d.producto = undefined;
                  d.cantidad = 0;
                  d.factor = 0;
                })}>
          Cancelar
        </button>
        : null
    }
    <button type="button"
            className="h-10 mt-auto mb-2 info"
            onClick={addConversion}>
      <FontAwesomeIcon icon={faPlus}/>
    </button>
  </div>
}

const ConversionesListHeader = () => (
  <SimpleHeader labels={[strings.producto, strings.cantidad, strings.total]}
                className="conversion-target"/>
)

interface ProductosGranelPorUbicacionProps {
  control: Control<ConversionForm>
  onGranelSelect?: (p: InventarioSucursalEntry | undefined) => void
}

interface ConversionGranelState {
  itemsGranel: InventarioSucursalEntry[]
  itemsGranelTipoProducto: ProductoInventario[] | undefined
  seleccionMayor?: InventarioSucursalEntry
  seleccionMenor?: ProductoInventario
  enableDownConversion: boolean
  isNetworking: boolean
}

const ProductosGranelPorUbicacion = ({control, onGranelSelect}: ProductosGranelPorUbicacionProps) => {
  const [{token}] = useAppState()
  const handleError = useCatchAndNotify();
  const [doRefresh, setRefresh] = useState(1);
  const [granelState, setGS] = useImmer<ConversionGranelState>({
    itemsGranel: [],
    itemsGranelTipoProducto: undefined,
    enableDownConversion: false,
    isNetworking: false
  });

  const ubicacion = useWatch({
    control,
    name: 'ubicacion',
    defaultValue: undefined
  });

  useEffect(() => {
    if (!onGranelSelect) return;
    onGranelSelect(granelState.seleccionMayor);
    return () => {
      onGranelSelect(undefined);
    }
  }, [granelState.seleccionMayor, onGranelSelect])

  useEffect(() => {
    if (!ubicacion?.value)
      return;
    const ubicacionId = ubicacion.value;
    pgGET(`/api/inventarios_sucursal_mvw?ubicacion_id=eq.${ubicacionId}&is_granel=eq.true&existencia=gt.0`, token)
      .then(toJSONOrToErr)
      .then((res: InventarioSucursalEntry[]) => {
        setGS(d => {
          d.itemsGranel = res;
          if (d.itemsGranel.length > 0) {
            d.seleccionMayor = res[0];
          }
        })
      })
      .catch(handleError);
  }, [handleError, setGS, token, ubicacion?.value, doRefresh]);

  useEffect(() => {
    if (!granelState.seleccionMayor || !granelState.enableDownConversion) {
      return;
    }
    const {tipo_producto, unidad, capacidad} = granelState.seleccionMayor;
    pgGET(`/api/productos_search_mvw?sucursal=eq._global&is_granel=eq.true&tipo_producto=eq.${tipo_producto}&unidad=eq.${unidad}&capacidad=lt.${capacidad}`,
      token)
      .then(toJSONOrToErr)
      .then((res: ProductoInventario[]) => {
        setGS(d => {
          d.itemsGranelTipoProducto = res;
          d.isNetworking = false;
        })
      })
      .catch(handleError);
  }, [granelState.seleccionMayor, handleError, setGS, token, granelState.enableDownConversion])

  const onConversion = useCallback(() => {
    const {seleccionMayor, seleccionMenor, isNetworking} = granelState;
    if (!ubicacion ||
      isNetworking ||
      !seleccionMayor ||
      !seleccionMenor ||
      !seleccionMayor.capacidad || // Verifica que capacidad no es 0 o undefined o null
      !seleccionMenor.capacidad ||
      seleccionMayor.unidad !== seleccionMenor.unidad || // Verifica que sean las mismas unidades
      seleccionMayor.capacidad % seleccionMenor.capacidad !== 0) // Verifica que no hay sobrante en la conversion
    {
      return
    }

    const payload = {
      ubicacion_id: ubicacion.value,
      producto_fuente_inventario_id: seleccionMayor.inventario_id, // id de inventario en sucursal
      producto_fuente_producto_id: seleccionMayor.producto_id, // id de producto
      productos: [
        {
          producto_id: seleccionMenor.id, // id de producto a agregar
          cantidad: seleccionMayor.capacidad / seleccionMenor.capacidad
        }
      ],
    }
    setGS(d => {
      d.isNetworking = true;
    });
    pgPOST('/api/rpc/do_conversion', JSON.stringify(payload), token,
      {headers: {'Prefer': 'params=single-object'}})
      .then(toJSONOrToErr)
      .then(() => {
        setRefresh(ps => ps + 1)
        setGS(d => {
          d.isNetworking = false;
          d.enableDownConversion = false;
          d.seleccionMenor = undefined;
        })
      })
      .catch(e => {
        handleError(e);
        setGS(d => {
          d.isNetworking = false;
        })
      })

  }, [granelState, handleError, setGS, token, ubicacion]);

  return ubicacion?.value ?
    <div className="conversiones-granel-grid">
      <label>
            <span>
              {strings.productosgranelenubicacion}
            </span>
        <select size={7} className="st ventas-control"
                value={granelState.seleccionMayor?.codigo}
                onChange={e => {
                  setGS(d => {
                    d.seleccionMayor = d.itemsGranel.find(ig => ig.codigo === e.target.value)
                    d.seleccionMenor = undefined;
                    if (d.enableDownConversion) {
                      d.isNetworking = true;
                      d.itemsGranelTipoProducto = undefined;
                    }
                  })
                }}>
          {granelState.itemsGranel.map(item => (
            <option
              key={item.codigo}
              value={item.codigo}>[{item.existencia}] {item.nombre} ({item.capacidad}{item.unidad})</option>))}
        </select>
      </label>
      <div className="flex flex-col w-full mt-8">
        {
          granelState.enableDownConversion ?
            <button className="danger" type="button"
                    disabled={granelState.isNetworking}
                    onClick={_ => setGS(d => {
                      d.enableDownConversion = false;
                      d.itemsGranelTipoProducto = undefined;
                    })
                    }>Cancelar</button>
            :
            <button className="info" type="button"
                    disabled={granelState.isNetworking}
                    onClick={_ => setGS(d => {
                      d.enableDownConversion = true;
                      d.isNetworking = true;
                    })}>Convertir a menor capacidad<FontAwesomeIcon icon={faFilter} className="ml-2"/>
            </button>
        }
        {
          granelState.enableDownConversion && granelState.seleccionMenor && granelState.isNetworking ?
            <FontAwesomeIcon icon={faSpinner} className="self-center mt-auto mb-auto animate-spin"/>
            : null
        }
        {
          granelState.enableDownConversion && granelState.seleccionMenor ?
            <button className="mt-auto mb-4 info"
                    type="button"
                    onClick={onConversion}
                    disabled={granelState.isNetworking}>
              <FontAwesomeIcon icon={faChevronLeft} className="mr-2"/>Agregar
            </button>
            : null
        }
      </div>
      <DownConversionWidget enable={granelState.enableDownConversion}
                            granelFuente={granelState.seleccionMayor}
                            productosGranelMenores={granelState.itemsGranelTipoProducto}
                            isNetworking={granelState.isNetworking}
                            onSeleccion={pi => {
                              setGS(d => {
                                d.seleccionMenor = pi
                              })
                            }}
      />
    </div>
    : null
}

interface DownConversionWidgetProps {
  enable: boolean
  granelFuente: InventarioSucursalEntry | undefined
  productosGranelMenores: ProductoInventario[] | undefined
  onSeleccion: (pi: ProductoInventario) => void
  isNetworking: boolean
}

const DownConversionWidget = ({
                                enable,
                                granelFuente,
                                productosGranelMenores,
                                isNetworking,
                                onSeleccion
                              }: DownConversionWidgetProps) => {
  const [seleccionMenor, setSM] = useImmer<ProductoInventario | undefined>(undefined);

  const downConversionFilter = useCallback((item: ProductoInventario) => {
    if (!granelFuente || !granelFuente.capacidad || !item.capacidad) return false;
    if (granelFuente.capacidad <= item.capacidad) return false;
    if (granelFuente.unidad !== item.unidad) return false;
    return granelFuente.capacidad % item.capacidad === 0
  }, [granelFuente]);

  useEffect(() => {
    if (seleccionMenor) {
      onSeleccion(seleccionMenor)
    }
  }, [onSeleccion, seleccionMenor])
  useEffect(() => {
    setSM(undefined);
    return () => {
      setSM(undefined);
    }
  }, [setSM]);

  const productosValidos = useMemo(() => {
    if (!productosGranelMenores) return undefined;
    return productosGranelMenores.filter(downConversionFilter)
  }, [downConversionFilter, productosGranelMenores]);

  const amountConversion = useMemo(() => {
    if (!granelFuente || !seleccionMenor) return 0;
    return granelFuente.capacidad / seleccionMenor.capacidad
  }, [granelFuente, seleccionMenor]);

  return enable && granelFuente ?
    <div className="flex flex-col flex-grow">
      {productosValidos && productosValidos.length > 0 ?
        <Fragment>
          <label className="w-full">
            <span>Productos a granel disponibles</span>
            <select size={5} className="w-full st"
                    value={seleccionMenor?.codigo}
                    disabled={isNetworking}
                    onChange={e => {
                      setSM(productosValidos.find(pi => pi.codigo === e.target.value));
                    }}>
              {productosValidos
                .map(item => (
                  <option key={item.codigo}
                          value={item.codigo}>[{granelFuente.capacidad / item.capacidad}] {item.nombre} ({item.capacidad}{item.unidad})</option>
                ))}
            </select>
          </label>
          {
            seleccionMenor ?
              <span
                className="text-sm font-bold">Convertir {granelFuente.nombre} a {amountConversion} de {seleccionMenor.nombre}</span>
              : null
          }
        </Fragment> : (
          (productosValidos && productosValidos.length === 0) ?
            <div className="mt-8 text-sm font-bold">No hay productos a granel del mismo tipo
              ({granelFuente.tipo_producto})
              de menor capacidad</div>
            : <FontAwesomeIcon icon={faSpinner} className="self-center m-auto animate-spin"/>
        )
      }
    </div>
    : null
}

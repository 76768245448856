import {faMinusCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useCallback, useMemo} from 'react';
import swal from 'sweetalert';
import {useImmer} from 'use-immer';
import {useCatalog, usePermissions} from './hooks';
import {ProductoSearch} from './ProductosSearch';
import {strings} from './strings';
import {Descuento, PGError, ProductoInventario, SelectOption} from './types';
import {DatePicker} from './uicomponents/DatePicker';
import {SimpleHeader, UISelect} from './uicomponents/Generic';
import {format} from "date-fns";
import {pgPOST} from "./networking";
import {useAppState} from "./stores/AppStateStore";
import {useAppNotifications} from "./uicomponents/Notifications";

export const VentasPage = () => {
  const {
    ventaState,
    setVentaState,
    catCanalesVenta: [, canalesVenta],
    catTiposCliente: [, tiposCliente],
    catUbicaciones: ubicaciones,
    catFormaPago: [, formaPago],
    catDescuentos: [descuentos],
    addProducto,
    addCantidadProducto,
    remCantidadProducto,
    reset,
    ventaHasProductos,
    saveVenta,
    addDescuento,
    comisionTDD,
    comisionTDC,
    totalVenta
  } = useNewVenta()

  const [{profile}] = useAppState();
  const permissions = usePermissions();

  return <div className="flex flex-col items-center flex-grow content-single">
    <h1 className="self-start py-4 text-xl font-bold">Nueva venta</h1>
    <div className="flex items-center justify-between w-full mb-4">
      <div><span className="font-bold">{strings.vendedor}: </span>{profile?.nombre || ''}</div>
      <div>
        <DatePicker
          onChange={v => setVentaState(d => {
            d.fecha = v
          })}
          value={ventaState.fecha}
        />
      </div>
    </div>
    <div className="flex items-center justify-between w-full space-x-4">
      <label className="ventas-control">
        <span>{strings.canaldeventa}</span>
        <UISelect options={canalesVenta}
                  value={ventaState.canal}
                  onChange={(v: SelectOption) => setVentaState(d => {
                    d.canal = v
                  })}
        />
      </label>
      <label className="ventas-control">
        <span>{strings.ubicacionproducto}</span>
        <UISelect options={ubicaciones}
                  value={ventaState.ubicacionProducto}
                  isDisabled={ventaHasProductos}
                  onChange={(v: SelectOption) => setVentaState(d => {
                    d.ubicacionProducto = v
                  })}
        />
      </label>
      <label className="ventas-control">
        <span>{strings.tipodecliente}</span>
        <UISelect options={tiposCliente}
                  value={ventaState.tipoCliente}
                  onChange={(v: SelectOption) => setVentaState(d => {
                    d.tipoCliente = v
                  })}
        />
      </label>
      <label className="ventas-control">
        <span>{strings.formadepago}</span>
        <UISelect options={formaPago}
                  value={ventaState.formaPago}
                  onChange={(v: SelectOption) => setVentaState(d => {
                    d.formaPago = v
                  })}
        />
      </label>
    </div>
    <div className="flex justify-center w-full pt-2 pb-4">
      <ProductoSearch onSelectProducto={addProducto}
                      onlyInStock
                      disabled={!ventaState.ubicacionProducto}
                      ubicacion={ventaState.ubicacionProducto?.label}/>
    </div>
    <div className="w-full py-2 font-bold text-center bg-azure">
      {strings.productos}
    </div>
    <div className="w-full">
      <SimpleHeader
        labels={[strings.codigo, strings.producto,
          strings.costounitario, strings.cantidad, strings.subtotal,
          strings.descuento, strings.descuento, strings.comision, strings.total
        ]}
        className="grid-ventas bg-azure"
      />
      {
        ventaState.productos.map(p => <ProductoVentaItem
          key={p.id}
          producto={p}
          onAddCantidad={addCantidadProducto}
          onRemCantidad={remCantidadProducto}
          descuentos={descuentos}
          onAddDescuento={addDescuento}
          comisionTDD={comisionTDD}
          comisionTDC={comisionTDC}
          formaPago={ventaState.formaPago?.label}
        />)
      }
    </div>
    <div className="flex w-full py-2">
      {
        ventaState.productos.length > 0 ?
          <div className="px-4 py-2 ml-auto mr-0 bg-azure">
            <span className="text-sm font-bold">Total: </span>${totalVenta.toFixed(2)}</div>
          : null
      }
    </div>
    {
      permissions.ventas.canCreate ?
        <div className="flex justify-around w-full py-4">
          <button className="danger"
                  tabIndex={-1}
                  onClick={() => {
                    swal({
                      text: strings.seperderanlosdatos,
                      buttons: ["Regresar", true]
                    }).then(v => {
                      if (v) {
                        reset();
                      }
                    })
                  }}
          >{strings.cancelar}</button>
          <button className="info"
                  onClick={() => {
                    swal({
                      text: strings.finalizarventa,
                      buttons: ["Regresar", true]
                    }).then(v => {
                      if (v) {
                        saveVenta(ventaState);
                      }
                    })
                  }}
          >{strings.cobrar}</button>
        </div>
        : null
    }
  </div>
}

interface ProductoVentaItemProps {
  producto: ProductoVenta
  onAddCantidad: (pv: ProductoVenta) => void
  onRemCantidad: (pv: ProductoVenta) => void
  descuentos: Descuento[]
  onAddDescuento: (pv: ProductoVenta, d?: Descuento) => void
  comisionTDD?: Descuento
  comisionTDC?: Descuento
  formaPago?: string
}

const ProductoVentaItem = ({
                             producto,
                             onAddCantidad,
                             onRemCantidad,
                             descuentos,
                             onAddDescuento,
                             comisionTDD,
                             comisionTDC,
                             formaPago
                           }: ProductoVentaItemProps) => {
  const p = producto.producto;
  const [descuento, comision, total] = useMemo(() => {
    const total = p.precio_publico * producto.cantidad;
    const descuento =
      producto.descuento && producto.descuento < 0 ?
        (total * producto.descuento)
        : 0;
    let comision =
      producto.descuento && producto.descuento > 0 ?
        (total * producto.descuento)
        : 0;
    if (formaPago === 'TDD' && comisionTDD) {
      comision += (comisionTDD.descuento * total);
    }
    if (formaPago === 'TDC' && comisionTDC) {
      comision += (comisionTDC.descuento * total);
    }
    return [descuento, comision, total + descuento + comision]
  }, [comisionTDC, comisionTDD, formaPago, p.precio_publico, producto.cantidad, producto.descuento]);

  return <div className="w-full py-2 grid-ventas crud-grid odd:bg-azure hover:bg-nyellow-light">
    <div>{p.codigo}</div>
    <div>{p.nombre}</div>
    <div>${p.precio_publico?.toFixed(2) ?? 0}</div>
    <div className="flex items-center justify-center w-full">
      <FontAwesomeIcon icon={faMinusCircle}
                       onClick={() => onRemCantidad(producto)}
                       className="text-sm text-gray-900 cursor-pointer hover:text-red-500"/>
      <div className="w-1/2">{producto.cantidad}</div>
      <FontAwesomeIcon icon={faPlusCircle}
                       className="text-sm text-gray-900 cursor-pointer hover:text-pblue"
                       onClick={() => onAddCantidad(producto)}
      />
    </div>
    <div>${(p.precio_publico * producto.cantidad).toFixed(2)}</div>
    <div>
      <select className="st max-20ch" onChange={e => onAddDescuento(producto, e.target.value === '-1'
        ? undefined : descuentos.find(d => d.id.toString() === e.target.value))}>
        <option value="-1">Sin descuento</option>
        {descuentos.map(d => (
          <option key={d.id} value={d.id}>{d.nombre}</option>))}
      </select>
    </div>
    <div>
      ${descuento?.toFixed(2) ?? 0}
    </div>
    <div>
      ${comision?.toFixed(2) ?? 0}
    </div>
    <div>${total?.toFixed(2) ?? 0}</div>
  </div>
}

interface ProductoVenta {
  producto: ProductoInventario
  descuento_id?: number,
  descuento?: number
  cantidad: number
  id: number
}

interface Venta {
  productos: ProductoVenta[]
  canal: SelectOption | null
  tipoCliente: SelectOption | null
  ubicacionProducto: SelectOption | null
  descuento: SelectOption | null
  formaPago: SelectOption | null
  fecha: Date | undefined
}

interface NewVenta {
  productos: {
    producto_id: number,
    cantidad: number,
    descuento_id: number | undefined
  }[]
  canal_id: number | string
  tipo_cliente_id: number | string
  ubicacion_producto_id: number | string
  forma_pago_id: number | string
  fecha: string // ISO 8601 Date (YYYY-MM-DD)
}

const newVenta = (): Venta => ({
  productos: [],
  canal: null,
  tipoCliente: null,
  ubicacionProducto: null,
  formaPago: null,
  descuento: null,
  fecha: new Date()
});

let prodId = 0;

const useNewVenta = () => {
  const [ventaState, setVentaState] = useImmer(newVenta())
  const catCanalesVenta = useCatalog("cat_canales_venta");
  const catTiposCliente = useCatalog("cat_tipos_cliente");
  const [, catUbicaciones] = useCatalog("cat_ubicaciones_producto");
  const catDescuentos = useCatalog<Descuento>("cat_descuentos?nombre=neq.TDC&nombre=neq.TDD&is_habilitado=eq.true");
  const catTDDTDC = useCatalog<Descuento>("cat_descuentos?or=(nombre.eq.TDC,nombre.eq.TDD)");
  const catFormaPago = useCatalog("cat_formas_pago");
  const [{token}] = useAppState();
  const {addNotification} = useAppNotifications();
  const permissions = usePermissions();
  const ubicacionesPermitidas = new Set(permissions.sucursal)

  const addProducto = useCallback((p: ProductoInventario) => {
    setVentaState(d => {
      const existing = d.productos.find(dp => p.id === dp.producto.id);
      if (existing) {
        if (p.existencia >= (existing.cantidad + 1)) {
          existing.cantidad++;
        } else {
          addNotification('No hay existencia', `No hay suficientes existencias de ${p.nombre} para agregar`)
        }
      } else {
        d.productos.push({id: prodId++, cantidad: 1, producto: p});
      }
    });
  }, [addNotification, setVentaState]);

  const addDescuento = useCallback((pv: ProductoVenta, descuento?: Descuento) => {
    setVentaState(d => {
      const producto = d.productos.find(pd => pd.id === pv.id)
      if (!producto) return;
      producto.descuento_id = descuento?.id;
      producto.descuento = descuento?.descuento;
    });
  }, [setVentaState]);

  const addCantidadProducto = useCallback((pv: ProductoVenta) => {
    setVentaState(d => {
      const dpv = d.productos.find(pd => pd.id === pv.id);
      if (dpv) {
        if (dpv.producto.existencia >= (dpv.cantidad + 1)) {
          dpv.cantidad += 1;
        } else {
          addNotification('No hay existencia',
            `No hay suficientes existencias de ${pv.producto.nombre} para agregar`);
        }
      }
    });
  }, [addNotification, setVentaState]);

  const remCantidadProducto = useCallback((pv: ProductoVenta) => {
    setVentaState(d => {
      const dpv = d.productos.find(pd => pd.id === pv.id);
      if (dpv && dpv.cantidad > 1) {
        dpv.cantidad -= 1;
      } else if (dpv) {
        const idx = d.productos.indexOf(dpv);
        d.productos.splice(idx, 1);
      }
    })
  }, [setVentaState])

  const reset = useCallback(() => {
    setVentaState(newVenta());
  }, [setVentaState]);

  const saveVenta = useCallback((venta: Venta) => {
    if (!venta.canal) {
      addNotification('Error', "No se especifico canal de venta");
      return;
    }
    if (!venta.formaPago) {
      addNotification('Error', "No se especifico forma de pago");
      return;
    }
    if (!venta.ubicacionProducto) {
      addNotification('Error', "No se especifico ubicacion de producto");
      return;
    }
    if (!venta.tipoCliente) {
      addNotification('Error', "No se especifico tipo de cliente");
      return;
    }
    if (!venta.fecha) {
      addNotification('Error', "No se especifico fecha");
      return;
    }
    if (!venta.productos || venta.productos.length === 0) {
      addNotification('Error', "No se agregaron productos");
      return;
    }
    const payload: NewVenta = ({
      canal_id: venta.canal.value,
      forma_pago_id: venta.formaPago.value,
      tipo_cliente_id: venta.tipoCliente.value,
      ubicacion_producto_id: venta.ubicacionProducto.value,
      fecha: format(venta.fecha, 'yyyy-MM-dd'),
      productos: venta.productos.map(p => ({
        producto_id: p.producto.id!!,
        cantidad: p.cantidad,
        descuento_id: p.descuento_id
      }))
    });

    pgPOST('/api/rpc/nueva_venta', JSON.stringify(payload), token,
      {headers: {'Prefer': 'params=single-object'}})
      .then(m => {
        if (m.status >= 200 && m.status < 300) {
          m.json()
            .then(() => {
              addNotification('Venta completada');
              reset();
            });
        } else {
          m.json()
            .then((e: PGError) => {
              addNotification(e.message || 'Error', e.hint);
            })
        }
      })
  }, [addNotification, reset, token])

  const comisionTDD = catTDDTDC[0].find(t => t.nombre === 'TDD');
  const comisionTDC = catTDDTDC[0].find(t => t.nombre === 'TDC');

  const totalVenta = useMemo(() => {
    return ventaState.productos.reduce((acc, p) => {
      const subtotal = p.cantidad * p.producto.precio_publico;
      const descuento = subtotal * (p.descuento ?? 0);
      const comTDD = ventaState.formaPago && ventaState.formaPago.label === 'TDD' ?
        subtotal * (comisionTDD?.descuento ?? 0) : 0;
      const comTDC = ventaState.formaPago && ventaState.formaPago.label === 'TDC' ?
        subtotal * (comisionTDC?.descuento ?? 0) : 0;

      return acc + (subtotal + descuento + comTDC + comTDD);
    }, 0);
  }, [ventaState.productos, comisionTDC, comisionTDD, ventaState.formaPago]);

  return {
    ventaState,
    setVentaState,
    catCanalesVenta,
    catTiposCliente,
    catUbicaciones: catUbicaciones.filter(u => ubicacionesPermitidas.has(u.value)),
    catDescuentos,
    catFormaPago,
    addProducto,
    addCantidadProducto,
    remCantidadProducto,
    reset,
    saveVenta,
    ventaHasProductos: ventaState.productos.length > 0,
    addDescuento,
    comisionTDD,
    comisionTDC,
    totalVenta
  };
}

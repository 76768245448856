import {Permissions} from "./types";

export const perfiles: { [k: string]: Permissions } = {
  'Administrador': {
    catalogo: {canCreate: true, canView: true, canUpdate: true},
    ventas: {canCreate: true, canView: true},
    entradasalida: {canView: true, canCreate: true, canApprove: true},
    reportes: {canView: true},
    productos: {canView: true, canCreate: true, canUpdate: true},
    inventario: {canView: true, canCreate: true, canUpdate: true},
    configuracion: {canView: true, canCreate: true, canUpdate: true},
    conversiones: {canView: true, canCreate: true},
    sucursal: []
  },
  'Ventas generales': {
    catalogo: {canCreate: false, canView: true, canUpdate: false},
    ventas: {canCreate: true, canView: true},
    entradasalida: {canView: true, canCreate: false, canApprove: false},
    reportes: {canView: true},
    productos: {canView: true, canCreate: false, canUpdate: false},
    inventario: {canView: true, canCreate: false, canUpdate: false},
    configuracion: {canView: false, canCreate: false, canUpdate: false},
    conversiones: {canView: false, canCreate: false},
    sucursal: []
  },
  'Auxiliar contable': {
    catalogo: {canCreate: false, canView: true, canUpdate: false},
    ventas: {canCreate: false, canView: true},
    entradasalida: {canView: true, canCreate: true, canApprove: false},
    reportes: {canView: true},
    productos: {canView: true, canCreate: true, canUpdate: true},
    inventario: {canView: true, canCreate: true, canUpdate: true},
    configuracion: {canView: false, canCreate: false, canUpdate: false},
    conversiones: {canView: false, canCreate: false},
    sucursal: []
  },
  'Caja de ventas': {
    catalogo: {canCreate: false, canView: false, canUpdate: false},
    ventas: {canCreate: true, canView: true},
    entradasalida: {canView: false, canCreate: false, canApprove: false},
    reportes: {canView: true},
    productos: {canView: true, canCreate: false, canUpdate: false},
    inventario: {canView: true, canCreate: false, canUpdate: false},
    configuracion: {canView: false, canCreate: false, canUpdate: false},
    conversiones: {canView: false, canCreate: false},
    sucursal: []
  },
  'Otro': {
    catalogo: {canCreate: false, canView: false, canUpdate: false},
    ventas: {canCreate: false, canView: false},
    entradasalida: {canView: false, canCreate: false, canApprove: false},
    reportes: {canView: false},
    productos: {canView: false, canCreate: false, canUpdate: false},
    inventario: {canView: false, canCreate: false, canUpdate: false},
    configuracion: {canView: false, canCreate: false, canUpdate: false},
    conversiones: {canView: false, canCreate: false},
    sucursal: []
  }
}

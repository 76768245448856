import React, {useCallback, useEffect, useState} from 'react';
import {NavList} from "./uicomponents/NavList";
import {Redirect, Route, Switch, useHistory, useParams, useRouteMatch} from "react-router-dom";
import {SimpleHeader, SimpleSinglePage} from "./uicomponents/Generic";
import {useCatalog, useListingWithPagination, usePermissions} from "./hooks";
import {strings} from "./strings";
import {PaginationControl} from "./uicomponents/Listing";
import {useImmer} from "use-immer";
import {Controller, useForm} from "react-hook-form";
import {PGError, Producto, SimpleCatalog} from "./types";
import {DatePicker} from "./uicomponents/DatePicker";
import {ProductoSearch} from "./ProductosSearch";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import swal from "sweetalert";
import {useAppState} from "./stores/AppStateStore";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {pgGET, pgPOST} from './networking';
import {formatISO, parseISO} from 'date-fns';
import {useAppNotifications} from "./uicomponents/Notifications";
import {faPen} from "@fortawesome/free-solid-svg-icons";


interface Nota {
  id: number
  tipo_nota: 'entrada' | 'salida'
  tipo_movimiento: string
  fecha: string
  usuario: string
  origen: string
  destino: string
  aprovado_por: string
}

export const NotasPage = () => {
  const match = useRouteMatch();
  const permissions = usePermissions();
  const notasPage = [
    {label: 'Notas', url: 'listado'},
    {
      label: 'Nueva nota',
      url: 'nueva',
      canShow: permissions.entradasalida.canCreate
    }
  ]
  return <div className="flex flex-col items-center content-single">
    <NavList items={notasPage}/>
    <Switch>
      <Route path={match.path} exact><Redirect to='/notas/listado'/></Route>
      <Route path={`${match.path}/listado`} exact><ListadoNotas/></Route>
      <Route path={`${match.path}/nueva`}><NuevaNota/></Route>
      <Route path={`${match.path}/edit/:idNota`}><NuevaNota/></Route>
      <Route path={`${match.path}/:idNota`}><DetalleNota/></Route>
    </Switch>
  </div>
}

const ListadoNotas = () => {
  const {items, ...pagination} =
    useListingWithPagination<Nota>('/api/notas_entrada_salida_vw', {'order': 'id.desc'})
  const history = useHistory()
  const permissions = usePermissions();

  return <SimpleSinglePage>
    <h1 className="text-lg font-bold">Notas de entrada y salida</h1>
    <div className="w-full mt-4">
      <SimpleHeader labels={[strings.tipo_nota, strings.tipo_movimiento,
        strings.origen, strings.destino, strings.fecha, strings.aprovado_por]}
                    className="listado-notas"/>
      {
        items.map(i => (
          <div className="py-2 cursor-pointer crud-grid listado-notas odd:bg-azure hover:bg-nyellow-light"
               key={i.id}
               onClick={() => {
                 if (!i.aprovado_por && permissions.entradasalida.canApprove) {
                   history.push(`/notas/edit/${i.id}`)
                 } else {
                   history.push(`/notas/${i.id}`)
                 }
               }
               }>
            <div className="capitalize">{i.tipo_nota}</div>
            <div>{i.tipo_movimiento}</div>
            <div>{i.origen}</div>
            <div>{i.destino}</div>
            <div>{i.fecha}</div>
            <div>{i.aprovado_por}</div>
            <FontAwesomeIcon icon={faPen} className="text-pblue"/>
          </div>
        ))
      }
    </div>
    <PaginationControl currentPage={pagination.currentPage}
                       onPrev={pagination.prevPage}
                       onNext={pagination.nextPage}
                       showNext={pagination.hasNextPage}
                       showPrev={pagination.hasPrevPage}
    />
  </SimpleSinglePage>
}

const DetalleNota = () => {
  const {idNota} = useParams<{ idNota?: string }>()
  const [productos, setProductos] = useState<ProductoNotaDetalle[]>();
  const [detalle, setDetalle] = useState<Nota | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [{token}] = useAppState();
  useEffect(() => {
    if (!idNota) {
      setIsLoading(false);
    }
    pgGET(`/api/notas_entrada_salida_vw?id=eq.${idNota}&order=id.asc`, token)
      .then(r => r.json())
      .then(r => {
        setDetalle(r[0]);
        setIsLoading(false);
      });
    pgGET(`/api/notas_producto_detalle_vw?nota_id=eq.${idNota}&order=producto_id.asc`, token)
      .then(r => r.json())
      .then(r => setProductos(r));
  }, [idNota, token]);

  if (!idNota || (!isLoading && !detalle)) {
    return <NotaDesconocida/>
  }
  return <SimpleSinglePage>
    <div className="flex items-center justify-between pb-2">
      <h1 className="text-lg font-bold">Nota #{detalle?.id}</h1>
      <div>{detalle?.fecha}</div>
    </div>
    <div className="mb-2"><span className="font-bold">Capturado por: </span>{detalle?.usuario}</div>
    <div className="flex items-center justify-between my-4 space-x-4">
      <div><span className="font-bold">{strings.tipo_nota}: </span>
        <span className="capitalize">{detalle?.tipo_nota}</span></div>
      <div><span className="font-bold">{strings.tipo_movimiento}: </span>
        {detalle?.tipo_movimiento}</div>
      <div><span className="font-bold">{strings.origen}: </span>
        {detalle?.origen || ''}</div>
      <div><span className="font-bold">{strings.destino}: </span>
        {detalle?.destino || ''}</div>
    </div>

    <div className="w-full py-2 font-bold text-center bg-azure">
      {strings.productos}
    </div>
    <div className="w-full mb-4">
      <SimpleHeader labels={[strings.codigo, strings.producto, strings.cantidad,
        strings.costo, strings.total]}
                    className="listado-detalle-notas"
      />
      {
        productos?.map((p, i) => (
          <div className="py-2 crud-grid listado-detalle-notas hover:bg-nyellow-light odd:bg-azure"
               key={p.codigo}>
            <div>{p.codigo}</div>
            <div>{p.nombre}</div>
            <div>{p.cantidad}</div>
            <div>${p.costo?.toFixed(2) ?? 0}</div>
            <div>${(p.costo * p.cantidad).toFixed(2)}</div>
          </div>
        ))
      }
    </div>
    <div className="flex items-center justify-around">
      <div><span className="font-bold">{strings.cantidadtotalproductos}:{' '}</span>
        {productos?.reduce((acc, val) => acc + val.cantidad, 0)}
      </div>
      <div><span className="font-bold">{strings.total}: </span>
        ${productos?.reduce((acc, val) => acc + (val.cantidad * val.costo), 0).toFixed(2)}
      </div>
    </div>
  </SimpleSinglePage>
}

const NotaDesconocida = () => {
  return <div>La nota seleccionada no existe</div>
}

type TipoNota = 'entrada' | 'salida';

interface NuevaNotaI {
  id?: number
  tipo_nota: TipoNota
  tipo_movimiento_id?: number
  fecha: Date
  user_id?: number
  origen_id: number
  destino_id: number
  productos: ProductoNota[]
  aprovar?: boolean
  descripcion?: string
}

interface ProductoNota {
  producto_id: number
  cantidad: number
  costo: number
  codigo: string
  nombre: string
}

interface ProductoNotaDetalle extends ProductoNota {
  id: number
}

type NuevaNotaForm = Omit<NuevaNotaI, 'productos'>;

interface CatTipoMovimiento extends SimpleCatalog {
  tipo_nota: TipoNota
}

const useNuevaNota = (productosIniciales?: ProductoNota[]) => {
  const [catTipoMov, , tipoMovResolver] = useCatalog<CatTipoMovimiento>('cat_tipo_movimiento');
  const [catUbicaciones, , ubicacionResolver] = useCatalog('cat_ubicaciones_producto');
  const [productos, setProductos] = useImmer<ProductoNota[]>(productosIniciales || []);
  const [{token, profile}] = useAppState();
  const [isNetworking, setNetworking] = useState(false);
  const {addNotification} = useAppNotifications();
  const history = useHistory();

  useEffect(() => {
    if (productosIniciales) {
      setProductos(productosIniciales)
    }
  }, [productosIniciales, setProductos])


  const onProductoAdd = useCallback((p: Producto) => {
    setProductos(d => {
      const idx = d.findIndex(dp => dp.producto_id === p.id);
      if (idx === -1) {
        d.push(({producto_id: p.id!!, cantidad: 1, costo: p.costo, codigo: p.codigo, nombre: p.nombre}))
      } else {
        d[idx].cantidad += 1;
      }
    })
  }, [setProductos])

  const updateProductoQuantity = useCallback((amount: number, idx: number) => {
    setProductos(d => {
      d[idx].cantidad = amount;
    })
  }, [setProductos]);

  const onDelete = useCallback(idx => {
    setProductos(d => {
      d.splice(idx, 1);
    })
  }, [setProductos]);

  const onSubmit = useCallback((nf: NuevaNotaForm) => {
    const payload = {
      ...nf,
      destino_id: nf.destino_id ? nf.destino_id : null,
      origen_id: nf.origen_id ? nf.origen_id : null,
      fecha: formatISO(nf.fecha, {representation: 'date'}),
      user_id: profile?.id,
      productos: productos.map(p => ({
        producto_id: p.producto_id,
        cantidad: p.cantidad
      }))
    }
    setNetworking(true);
    pgPOST("/api/rpc/upsert_nota", JSON.stringify(payload), token,
      {headers: {'Prefer': 'params=single-object'}})
      .then(res => {
        if (res.status >= 200 && res.status <= 299) {
          if (nf.aprovar && nf.id) {
            pgPOST("/api/rpc/aprovar_nota", JSON.stringify({_nota_id: nf.id}), token)
              .then(res => {
                if (res.status >= 200 && res.status <= 299) {
                  setNetworking(false);
                  history.push('/notas');
                } else {
                  res.json()
                    .then((e: PGError) => {
                      setNetworking(false);
                      addNotification(e.message || 'Error', e.hint);
                    });
                }
              });
          } else {
            setNetworking(false);
            history.push('/notas');
          }
        } else {
          res.json()
            .then((e: PGError) => {
              setNetworking(false);
              addNotification(e.message || 'Error', e.hint);
            });
        }
      })
  }, [addNotification, history, productos, profile?.id, token]);

  return {
    catTipoMov,
    tipoMovResolver,
    catUbicaciones,
    productos,
    onProductoAdd,
    updateProductoQuantity,
    onDelete,
    onSubmit,
    isNetworking,
    ubicacionResolver
  }
}

const NuevaNota = () => {
  const {idNota} = useParams<{ idNota?: string }>()
  let idNotaInt: string | undefined = undefined;
  if (idNota?.match(/^\d+$/)) {
    idNotaInt = idNota;
  }

  const [productosIniciales, setProductosIniciales] = useImmer<ProductoNota[] | undefined>(undefined);

  const {
    catTipoMov,
    tipoMovResolver,
    catUbicaciones,
    productos,
    onProductoAdd,
    updateProductoQuantity,
    onDelete,
    onSubmit,
    isNetworking,
    ubicacionResolver
  } = useNuevaNota(productosIniciales);

  const {register, watch, handleSubmit, control, reset} = useForm<NuevaNotaForm>();
  const watchTipoNota = watch("tipo_nota", 'entrada');
  const watchTipoMov = watch("tipo_movimiento_id");
  const watchFuente = watch("origen_id");
  const [ubicacion, setUbicacion] = useState<string | undefined>();
  const [showOrigen, setShowOrigen] = useState(false);
  const [showDestino, setShowDestino] = useState(false);
  const tipoNotaFilter = useCallback(
    (i: CatTipoMovimiento) => i.tipo_nota === watchTipoNota, [watchTipoNota])
  const [{token}] = useAppState();
  const permissions = usePermissions();

  useEffect(() => {
    setUbicacion(ubicacionResolver(watchFuente))
  }, [watchFuente, setUbicacion, ubicacionResolver])

  useEffect(() => {
    const isEntrada = watchTipoNota === 'entrada';
    const isTraspaso = tipoMovResolver(watchTipoMov || -1) === 'Traspaso';
    const isSalida = watchTipoNota === 'salida';
    const isSurtirTienda = tipoMovResolver(watchTipoMov || -1) === 'Surtir tienda'
    setShowOrigen((isEntrada && isTraspaso) || isSalida);
    setShowDestino((isSalida && isSurtirTienda) || isEntrada);
  }, [tipoMovResolver, watchTipoMov, watchTipoNota]);

  useEffect(() => {
    if (!idNotaInt) return;
    pgGET(`/api/notas?id=eq.${idNotaInt}`,
      token,
      {headers: {'Prefer': 'params=single-object'}})
      .then(n => n.json())
      .then(n => {
        const nota = n[0];
        if (!nota || nota.length === 0) {
          return;
        }
        const notaInicial: NuevaNotaForm = {
          id: nota.id,
          user_id: nota.user_id,
          origen_id: nota.origen_id,
          destino_id: nota.destino_id,
          tipo_nota: nota.tipo_nota,
          tipo_movimiento_id: nota.tipo_movimiento_id,
          descripcion: nota.descripcion,
          fecha: parseISO(nota.fecha)
        }

        setProductosIniciales(nota.productos);
        reset(notaInicial);
      });
  }, [idNotaInt, reset, setProductosIniciales, token]);

  return <SimpleSinglePage>
    <h1 className="text-lg font-bold">Nueva Nota</h1>
    <form className=""
          onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center justify-between w-full mb-4">
        <div>
          <span className="font-bold">{strings.usuario}: </span>Pruebas
        </div>
        <div>
          <Controller
            name="fecha"
            control={control}
            defaultValue={new Date()}
            render={({field: {ref, ...field}}) => (
              <DatePicker
                {...field}
              />
            )}/>
        </div>
      </div>
      <div className="flex items-center justify-between w-full mb-4 space-x-4">
        <label className="ventas-control">
          <span>Tipo de nota</span>
          <select {...register('tipo_nota')} required
                  disabled={isNetworking}
                  className="w-full st">
            <option value='entrada'>Entrada</option>
            <option value='salida'>Salida</option>
          </select>
        </label>
        <label className="ventas-control">
          <span>Tipo movimiento</span>
          <select {...register("tipo_movimiento_id")}
                  required
                  disabled={isNetworking}
                  className="w-full st"
                  defaultValue="">
            <option disabled value="">Seleccione uno</option>
            {catTipoMov.filter(tipoNotaFilter)
              .map(i => (
                <option key={i.id} value={i.id}>{i.nombre}</option>
              ))
            }
          </select>
        </label>
        {showOrigen ?
          <label className="ventas-control">
            <span>Origen</span>
            <select className="w-full st"
                    disabled={isNetworking}
                    {...register('origen_id')}
                    defaultValue=""
                    required>
              <option disabled value="">Seleccione uno</option>
              {catUbicaciones.map(u => (<option key={u.id} value={u.id}>{u.nombre}</option>))}
            </select>
          </label>
          : null
        }
        {
          showDestino ?
            <label className="ventas-control">
              <span>Destino</span>
              <select className="w-full st"
                      disabled={isNetworking}
                      {...register('destino_id')}
                      defaultValue=""
                      required>
                <option disabled value="">Seleccione uno</option>
                {catUbicaciones.map(u => (<option key={u.id} value={u.id}>{u.nombre}</option>))}
              </select>
            </label>
            : null
        }
      </div>
      {
        showOrigen && showDestino ?
          <div className="flex w-full">
            <label className="ventas-control">
              <span>{strings.descripcion_movimiento}</span>
              <input type="text"
                     className="w-full"
                     required
                     {...register('descripcion')}/>
            </label>
          </div>
          : null
      }
      <div className="flex items-center justify-center w-full py-4">
        <ProductoSearch className="ventas-control"
                        disabled={showOrigen && !ubicacion}
                        ubicacion={ubicacion}
                        onSelectProducto={onProductoAdd}/>
      </div>

      <div className="w-full py-2 font-bold text-center bg-azure">
        {strings.productos}
      </div>
      <div className="w-full mb-4">
        <SimpleHeader labels={[strings.codigo, strings.producto, strings.cantidad,
          strings.costo, strings.total]}
                      className="listado-producto-notas"
        />
        {
          productos.map((p, i) => (
            <div className="py-2 crud-grid listado-producto-notas hover:bg-nyellow-light odd:bg-azure"
                 key={p.codigo}>
              <div>{p.codigo}</div>
              <div>{p.nombre}</div>
              <input type="text"
                     className="w-full text-center"
                     value={p.cantidad}
                     disabled={isNetworking}
                     onChange={e => {
                       const q = e.target.value.trim();
                       if (q === '') {
                         updateProductoQuantity(0, i)
                       } else if (q.match(/^\d+$/)) {
                         updateProductoQuantity(parseInt(q), i);
                       }
                     }}/>

              <div>${p.costo?.toFixed(2) ?? 0}</div>
              <div>${(p.costo * p.cantidad).toFixed(2)}</div>
              <div><FontAwesomeIcon
                icon={faTimesCircle}
                className="cursor-pointer hover:text-red-500"
                onClick={() => {
                  swal({
                    text: strings.seguroquedeseasborrar,
                    buttons: ["Cancelar", true]
                  }).then(v => {
                    if (v) {
                      onDelete(i);
                    }
                  })
                }}
              /></div>
            </div>
          ))
        }
      </div>
      <div className="flex items-center justify-around">
        <div><span className="font-bold">{strings.cantidadtotalproductos}:{' '}</span>
          {productos.reduce((acc, val) => acc + val.cantidad, 0)}
        </div>
        <div><span className="font-bold">{strings.total}: </span>
          ${productos.reduce((acc, val) => acc + (val.cantidad * val.costo), 0).toFixed(2)}
        </div>
      </div>
      <div className="flex items-center justify-end">
        {permissions.entradasalida.canApprove && idNotaInt ?
          <label className="flex items-center mr-auto">
            <span className="mr-4">Aprobar Nota</span>
            <input type="checkbox" {...register("aprovar")}/>
          </label>
          : null
        }
        {isNetworking ?
          <FontAwesomeIcon icon={faSpinner} className="ml-auto mr-4 text-gray-700 fa-spin"/>
          : null
        }
        <input className="info"
               type="submit"
               value="Guardar"
               disabled={isNetworking}
        />
      </div>
    </form>
  </SimpleSinglePage>
}

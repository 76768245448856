import {Token} from "./types";

export const fetcher = (url: RequestInfo, options?: RequestInit)
  : Promise<Response> => {
  return fetch(url, options)
}

export const pgGET = async (endpoint: RequestInfo,
  token?: Token,
  options?: RequestInit) => {
  return await pgReq(endpoint, 'GET', token,
    undefined,
    options)
}

export const pgPOST = async (endpoint: RequestInfo,
  payload: BodyInit | null,
  token?: Token,
  options?: RequestInit) => {
  return await pgReq(endpoint, 'POST', token,
    payload, options);
}

export const pgPATCH = async (endpoint: RequestInfo,
  payload: BodyInit | null,
  token?: Token,
  options?: RequestInit) => {
  return await pgReq(endpoint, 'PATCH', token,
    payload, options);
}

export const pgDELETE = async (endpoint: RequestInfo,
  token?: Token,
  options?: RequestInit
) => {
  return await pgReq(endpoint,
    'DELETE',
    token,
    undefined,
    options);
}

const pgReq = async (url: RequestInfo,
  method: string,
  token?: Token,
  payload?: BodyInit | null,
  options?: RequestInit) => {

  const tokenHeader = {
    'Authorization': `Bearer ${token?.token}`,
  };

  const defaultHeaders = {
    'Accept': 'application/json',
    'Content-type': 'application/json',
    'Prefer': 'return=representation'
  };
  const {headers: optionHeaders, ...normalizedOptions} = options ? options : {headers: {}};

  const finalHeaders =
    {
      ...defaultHeaders,
      ...(token ? tokenHeader : undefined),
      ...optionHeaders
    };

  return await fetch(url,
    {
      headers: finalHeaders,
      method: method,
      body: payload,
      ...normalizedOptions
    }
  );
}

import {useCallback, useEffect, useState} from "react";
import {useImmer} from 'use-immer';
import {pgDELETE, pgGET, pgPATCH, pgPOST} from "./networking";
import {useAppState} from "./stores/AppStateStore";
import {Idable, SimpleCatalog} from "./types";
import {Pagination} from "./uicomponents/Listing";

const getPageRange = (currentPage: number, itemsPerPage: number, next: boolean): string => {
  if (currentPage <= 0) {
    return `0-${itemsPerPage - 1}`;
  }
  if (next) {
    const nextPageStart = currentPage * itemsPerPage;
    return `${nextPageStart}-${nextPageStart + (itemsPerPage - 1)}`
  } else {
    const prevPageStart = ((currentPage - 1) * itemsPerPage);
    return `${prevPageStart}-${prevPageStart + (itemsPerPage - 1)}`
  }
}

const parseContentRange = (contentRange: string | null): [number, number, number] => {
  if (!contentRange) return [NaN, NaN, NaN];
  const [range, totalAsStr] = contentRange.split('/');
  const [startAsStr, endAsStr] = range?.split('-');
  const start = parseInt(startAsStr ?? '');
  const end = parseInt(endAsStr ?? '');
  const total = parseInt(totalAsStr ?? '');
  return [start, end, total];
}

const hasNextPage = (contentRange: string | null): boolean => {
  const [, end, total] = parseContentRange(contentRange)

  if (isNaN(total) || isNaN(end)) return false;
  return end < (total - 1);
}

const hasPrevPage = (contentRange: string | null): boolean => {
  const [start] = parseContentRange(contentRange)
  return !isNaN(start) && start !== 0;
}

export interface CRUDOperations<T> {
  newItem: (item: T) => Promise<T>
  updateItem: (id: number, item: T) => void
  deleteItem: (id: number) => void
}

export interface GETQueryParams {
  [key: string]: string
}

export const useSimpleCRUD = <T extends Idable>(endpoint: string,
  itemsPerPage: number = 100):
  [T[], CRUDOperations<T>, Pagination] => {

  const [items, setState] = useImmer<T[]>([]);

  const [{token}] = useAppState();

  const listing = useListingWithPagination<T>(endpoint,
    {order: 'id.asc'},
    itemsPerPage);

  useEffect(() => {
    setState(listing.items);
    return () => {
      setState([])
    };
  }, [listing.items, setState])

  const newItem = useCallback(async (item: T) => {
    const newItem = await pgPOST(endpoint, JSON.stringify(item), token);
    const newItemJSON = await newItem.json()
    const res = newItemJSON[0];
    setState(d => {
      d.unshift(res);
    });

    return res;
  }, [setState, endpoint, token]);

  const updateItem = useCallback(async (id: number, item: T) => {
    const updatedItem = await pgPATCH(`${endpoint}?id=eq.${id}`,
      JSON.stringify(item), token);
    const updatedItemJSON = (await updatedItem.json())[0];
    setState(d => {
      const idx = d.findIndex((i: Idable) => i.id === updatedItemJSON.id)
      d[idx] = updatedItemJSON;
    });
    return updatedItemJSON;
  }, [setState, endpoint, token]);

  const deleteItem = useCallback(async (id: number) => {
    await pgDELETE(`${endpoint}?id=eq.${id}`, token);
    setState(d => {
      const idx = d.findIndex((i: Idable) => i.id === id);
      d.splice(idx, 1);
    });
  }, [setState, endpoint, token]);

  return [items,
    {newItem, updateItem, deleteItem},
    {
      currentPage: listing.currentPage,
      onNext: listing.nextPage,
      onPrev: listing.prevPage,
      showNext: listing.hasNextPage,
      showPrev: listing.hasPrevPage
    }]
}

type Cat = readonly ({label: string, value: string})[];

export const useCatalog = <T extends SimpleCatalog>(endpoint: string): [T[], Cat, (value: string | number | undefined) => string | undefined] => {
  const [catalog, setCatalog] = useState<T[]>([]);
  const [catalogLV, setCatalogLV] = useState<Cat>([]);
  const [catalogVtoL, setCatVtoL] = useState(new Map<number, string>());
  const [{token}] = useAppState();

  useEffect(() => {
    pgGET(`/api/${endpoint}`, token)
      .then(j => j.json())
      .then((r: T[]) => {
        setCatalog(r);
        setCatalogLV(r.map(v => ({label: v.nombre, value: v.id.toString()})));
        setCatVtoL(_ => {
          const m = new Map<number, string>();
          r.forEach(v => {
            m.set(v.id, v.nombre);
          });
          return m;
        })
      });

  }, [endpoint, token]);

  const catGetter =
    useCallback((v: string | number | undefined) => {
      if (v === undefined) return undefined;
      if (typeof v === 'string') {
        return catalogVtoL.get(parseInt(v));
      }
      return catalogVtoL.get(v);
    }, [catalogVtoL]);

  return [catalog, catalogLV, catGetter];
}

export const toQueryString = (kv: { [key: string]: string }): string => {
  return Object.keys(kv).reduce((acc, val) => {
    return `${acc}&${val}=${kv[val]}`;
  }, '?');
}

export const useListingWithPagination = <T>(endpoint: string,
                                            queryParams: { [key: string]: string },
                                            itemsPerPage: number = 100) => {
  const [{token}] = useAppState();
  const [_hasNextPage, setHasNextPage] = useState(false)
  const [_hasPrevPage, setHasPrevpage] = useState(false)
  const [items, setItems] = useState<T[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const qs = toQueryString(queryParams);
  useEffect(() => {
    pgGET(`${endpoint}${qs}`,
      token,
      {
        headers: {
          'Range': getPageRange(currentPage, itemsPerPage, true),
          'Range-Unit': 'items',
          'Prefer': 'count=estimated'
        }
      })
      .then(async j => {
        const contentRange = j.headers.get('content-range');
        setHasNextPage(hasNextPage(contentRange))
        setHasPrevpage(hasPrevPage(contentRange))
        const r = await j.json();
        setItems(r);
      });
  }, [endpoint, token, itemsPerPage, currentPage, setHasPrevpage, setHasNextPage, qs])

  useEffect(() => {
    return () => {
      setItems([]);
      setCurrentPage(0);
    }
  }, [endpoint, token])

  const nextPage = useCallback(() => {
    setCurrentPage(v => v += 1)
  }, [setCurrentPage]);

  const prevPage = useCallback(() => {
    setCurrentPage(v => v -= 1)
  }, [setCurrentPage]);

  return {
    items,
    currentPage,
    hasNextPage: _hasNextPage,
    hasPrevPage: _hasPrevPage,
    nextPage,
    prevPage
  }
}

export const usePermissions = () => {
  const [{profile}, , logout] = useAppState();
  if (!profile) {
    console.log("Profile is undefined?");
    logout();
  }
  const {permissions} = profile!!
  return permissions;
}
import {PGError} from "./types";
import {useAppNotifications} from "./uicomponents/Notifications";
import {useCallback} from "react";

class PGErrorDetail extends Error {
  public hint: string;

  constructor(message: string, hint: string) {
    super(message);
    this.hint = hint;
  }
}

export function toJSONOrToErr(res: Response) {
  if (res.status < 200 || res.status > 299) {
    return res.json()
      .then((pgErr: PGError) => {
        throw new PGErrorDetail(pgErr.message, pgErr.hint);
      })
  } else {
    return res.json();
  }
}

export function useCatchAndNotify() {
  const {addNotification} = useAppNotifications()

  return useCallback((e: Error) => {
    if (e instanceof PGErrorDetail) {
      addNotification(e.message || 'Error', e.hint);
    } else {
      addNotification(e.name, e.message);
    }
  }, [addNotification]);
}
import {faDoorOpen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useAppState} from "../stores/AppStateStore";

export const Footer = () => {
  const [, , logout] = useAppState()
  return <div className="footer bg-gray-600 text-white flex items-center">
    <div className="container mx-auto text-sm flex items-center justify-end">
      <button type="button" onClick={logout}
              className="hover:border-iorange hover:text-iorange transition-colors duration-200">
        <FontAwesomeIcon icon={faDoorOpen} className="mr-2"/>
        Salir
      </button>
    </div>
  </div>
}
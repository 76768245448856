export const strings = {
  'catalogos': 'Catálogos',
  'ventas': 'Ventas',
  'notas': 'Notas entrada y salida',
  'conversiones': 'Conversiones',
  'reportes': 'Reportes',
  'productos': 'Productos',
  'inventarios': 'Inventarios',
  'configuracion': 'Configuración',
  'inicio': 'Inicio',
  'usuario': 'Usuario',
  'password': 'Contraseña',
  'title': 'Administración Inventarios',
  'menu': 'Menu',
  'canalesdeventa': 'Canales de venta',
  'tipocliente': 'Tipo de cliente',
  'ubicacionproducto': 'Ubicación producto',
  'ubicacion': 'Ubicación',
  'descuentos': 'Descuentos',
  'categorias': 'Categoría',
  'cancelar': 'Cancelar',
  'cobrar': 'Cobrar',
  'usuarios': 'Usuarios',
  'nombre': 'Nombre',
  'perfil': 'Perfil',
  'guardar': 'Guardar',
  'codigo': 'Código',
  'precio_publico': 'Precio público',
  'precio_distribuidor': 'Precio distribuidor',
  'precio_consignacion': "Precio consignación",
  'costo': 'Costo',
  'categoria': 'Categoría',
  'seguroquedeseasborrar': '¿Seguro que deseas borrar?',
  'nuevo': 'Nuevo',
  'actualizarCatalogo': "Actualizar catálogo",
  'canaldeventa': 'Canal de venta',
  'tipodecliente': 'Tipo de cliente',
  'vendedor': 'Vendedor',
  'agregar': 'Agregar',
  'producto': 'Producto',
  'costounitario': 'Costo unitario',
  'cantidad': 'Cantidad',
  'descuento': 'Descuento',
  'total': 'Total',
  'subtotal': 'Subtotal',
  'seperderanlosdatos': 'Se perderan los datos capturados',
  'finalizarventa': '¿Finalizar venta?',
  'existencia': 'Existencia',
  'stock_minimo': 'Stock minimo',
  'stock_maximo': 'Stock maximo',
  'sucursal': 'Sucursal',
  tipo_nota: 'Tipo de nota',
  tipo_movimiento: 'Tipo de movimiento',
  fecha: 'Fecha',
  destino: 'Destino',
  cantidadtotalproductos: 'Cantidad total de productos',
  origen: 'Origen',
  nuevaconversion: 'Nueva conversión',
  productogranel: 'Producto granel',
  seleccioneunproducto: 'Seleccione un producto',
  seguardaranlosdatos: 'Se guardarán los datos',
  formadepago: "Forma de pago",
  habilitado: "Habilitado",
  comision: "Comisión",
  agranel: "Producto a granel",
  tipo_producto: "Tipo de producto",
  capacidad: "Capacidad",
  unidad: "Unidad",
  factor: "Factor",
  faltaFechaInicio: "No se especificó fecha inicio",
  faltaFechaFin: "No se especificó fecha fin",
  fechasInvalidas: "Fechas para reportes son invalidas",
  costo_total: "Costo total",
  monto_comision: "Monto comisión",
  subtotal_comision: "Subtotal comisión",
  monto_descuento: "Monto descuento",
  canView: "Puede ver la página",
  canCreate: "Puede crear nuevos elementos",
  canUpdate: "Puede actualizar elementos existentes",
  canApprove: "Puede aprovar modificaciones",
  aprovado_por: "Aprobado por",
  descripcion_movimiento: "Descripción del movimiento",
  fecha_aprobacion: "Fecha aprobación",
  descripcion: "Descripción",
  total_producto: "Total producto",
  aprobado_por: "Aprobado por",
  productosgranelenubicacion: "Productos a granel en ubicación"
}

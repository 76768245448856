import {faCheckCircle, faEye, faPen, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import {useCatalog, usePermissions, useSimpleCRUD} from './hooks';
import {strings} from './strings';
import {ItemEditorModalForm, Usuario} from './types';
import {SimpleHeader, SimpleItem, SimpleModalForm, SimpleSinglePage} from './uicomponents/Generic';
import {Listing} from './uicomponents/Listing';
import {useForm} from "react-hook-form";
import {perfiles} from "./Perfiles";

export const ConfigPage = () => {
  const match = useRouteMatch();
  return <div className="flex flex-col center content-single">
    <Switch>
      <Route path={`${match.path}`} exact>
        <Redirect to={`${match.path}/usuarios`}/>
      </Route>
      <Route path={`${match.path}/usuarios`}>
        <Usuarios/>
      </Route>
    </Switch>
  </div>
}

const UsuariosHeader = () => (<SimpleHeader
    className="usuarios"
    labels={["Nombre", "Usuario"]}/>
)

const UsuarioItemFactory = (onUpdate: (u: Usuario) => void) =>
  (u: Usuario, canEdit?: boolean) => (
    <SimpleItem
      className="usuarios"
      values={[u.nombre, u.username]}
      onUpdate={() => onUpdate(u)}
      canEdit={canEdit}
    />
  )

const Usuarios = () => {
  const [users, {newItem, updateItem}] = useSimpleCRUD<Usuario>('/api/usuarios');
  const [item, setItem] = useState<Usuario | undefined>();
  const [modalKind, setModalKind] = useState<'new' | 'update' | undefined>();
  const permissions = usePermissions();

  return <SimpleSinglePage>
    <Listing items={users}
             title={strings.usuarios}
             header={UsuariosHeader}
             itemView={UsuarioItemFactory(d => {
               setItem(d);
               setModalKind('update');
             })}
             search={{searchKey: 'nombre'}}
             onNew={() => {
               setItem(undefined);
               setModalKind('new');
             }}
             canEdit={permissions.configuracion.canUpdate}
             canCreate={permissions.configuracion.canCreate}
    />
    {modalKind !== undefined ?
      <UsuarioForm
        isOpen
        onRequestClose={() => setModalKind(undefined)}
        item={item}
        title={modalKind === 'new' ? 'Nuevo usuario' : 'Actualizar usuario'}
        onSubmit={u => modalKind === 'new' ? newItem(u) : updateItem(u.id!!, u)}
      />
      : null}
  </SimpleSinglePage>
}


const UsuarioForm: ItemEditorModalForm<Usuario> =
  ({item, onSubmit, ...modalProps}) => {
    const {register, handleSubmit, formState, reset, watch, setValue} =
      useForm<Usuario>();

    const [, ubicaciones] = useCatalog("cat_ubicaciones_producto");
    const ubicacionesSel = watch('permissions.sucursal');
    const [isFirstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
      reset(item)
      return () => {
        reset();
      }
    }, [item, reset])

    useEffect(() => {
      if (ubicacionesSel &&
        ubicaciones.length > 0 &&
        ubicacionesSel.indexOf('Todos') !== -1) {
        setValue('permissions.sucursal', ubicaciones.map(vl => vl.value))
      }
    }, [ubicacionesSel, ubicaciones, setValue]);

    useEffect(() => {
      if (item &&
        item.permissions.sucursal.length > 0 &&
        ubicaciones.length > 0 &&
        isFirstLoad) {
        setValue('permissions.sucursal', item.permissions.sucursal);
        setFirstLoad(false)
      }
    }, [isFirstLoad, item, setValue, ubicaciones])

    return <SimpleModalForm
      {...modalProps}
      formState={formState}
      onSubmit={handleSubmit(onSubmit)}
      reset={reset}>
      <input type="text" className="hidden" hidden {...register('role')}
             defaultValue='inventariowebuser'/>
      <label>
        <span>{strings.usuario}</span>
        <input type="text" autoComplete="username" {...register('username')} required/>
      </label>
      <label>
        <span>{strings.password}</span>
        <input type="password" autoComplete="new-password" {...register('pass')} required/>
      </label>
      <label className="col-span-2">
        <span>{strings.nombre}</span>
        <input type="text" className="w-full" {...register('nombre')} required/>
      </label>

      <h3 className="py-1 text-sm font-bold text-gray-600 col-span-2">Permisos</h3>
      <label className="col-span-2">
        <span>{strings.ubicacion}</span>
        <select {...register('permissions.sucursal')}
                className="st w-full"
                multiple>
          <option value='Todos'>Todos</option>
          {ubicaciones.map(u => (
            <option value={u.value} key={u.value}>{u.label}</option>
          ))}
        </select>
      </label>

      <label className="col-span-2">
        <span>Perfiles</span>
        <select className="st w-full"
                defaultValue="Otro"
                onChange={e => {
                  const p = perfiles[e.target.value];
                  if (!p) return;
                  setValue('permissions', {...p});
                }}>
          {Object.keys(perfiles).map(p => (
            <option value={p} key={p}>{p}</option>
          ))}
        </select>

      </label>

      <div className="user-permissions col-span-2">
        <h4>{strings.catalogos}</h4>
        <div className="space-x-4 flex">
          <label title={strings.canView}>
            <FontAwesomeIcon icon={faEye} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.catalogo.canView')}/>
          </label>
          <label title={strings.canCreate}>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.catalogo.canCreate')}/>
          </label>
          <label title={strings.canUpdate}>
            <FontAwesomeIcon icon={faPen} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.catalogo.canUpdate')}/>
          </label>
        </div>

        <h4>{strings.ventas}</h4>
        <div className="space-x-4 flex">
          <label title={strings.canView}>
            <FontAwesomeIcon icon={faEye} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.ventas.canView')}/>
          </label>
          <label title={strings.canCreate}>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.ventas.canCreate')}/>
          </label>
        </div>


        <h4>{strings.notas}</h4>
        <div className="space-x-4 flex">
          <label title={strings.canView}>
            <FontAwesomeIcon icon={faEye} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.entradasalida.canView')}/>
          </label>
          <label title={strings.canCreate}>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.entradasalida.canCreate')}/>
          </label>
          <label title={strings.canApprove}>
            <FontAwesomeIcon icon={faCheckCircle} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.entradasalida.canApprove')}/>
          </label>
        </div>

        <h4>{strings.conversiones}</h4>
        <div className="space-x-4 flex">
          <label title={strings.canView}>
            <FontAwesomeIcon icon={faEye} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.conversiones.canView')}/>
          </label>
          <label title={strings.canCreate}>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.conversiones.canCreate')}/>
          </label>
        </div>

        <h4>{strings.reportes}</h4>
        <div className="space-x-4 flex">
          <label title={strings.canView}>
            <FontAwesomeIcon icon={faEye} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.reportes.canView')}/>
          </label>
        </div>

        <h4>{strings.productos}</h4>
        <div className="space-x-4 flex">
          <label title={strings.canView}>
            <FontAwesomeIcon icon={faEye} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.productos.canView')}/>
          </label>
          <label title={strings.canCreate}>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.productos.canCreate')}/>
          </label>
          <label title={strings.canUpdate}>
            <FontAwesomeIcon icon={faPen} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.productos.canUpdate')}/>
          </label>
        </div>

        <h4>{strings.inventarios}</h4>
        <div className="space-x-4 flex">
          <label title={strings.canView}>
            <FontAwesomeIcon icon={faEye} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.inventario.canView')}/>
          </label>
          <label title={strings.canCreate}>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.inventario.canCreate')}/>
          </label>
          <label title={strings.canUpdate}>
            <FontAwesomeIcon icon={faPen} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.inventario.canUpdate')}/>
          </label>
        </div>

        <h4>{strings.configuracion}</h4>
        <div className="space-x-4 flex">
          <label title={strings.canView}>
            <FontAwesomeIcon icon={faEye} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.configuracion.canView')}/>
          </label>
          <label title={strings.canCreate}>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.configuracion.canCreate')}/>
          </label>
          <label title={strings.canUpdate}>
            <FontAwesomeIcon icon={faPen} className="mr-2 text-gray-600"/>
            <input type="checkbox" {...register('permissions.configuracion.canUpdate')}/>
          </label>
        </div>
      </div>
      <div className="col-span-2 text-gray-600 text-sm">
        <div>
          <FontAwesomeIcon icon={faEye} className="mr-2 text-gray-600"/>
          <span className="">{strings.canView}</span>
        </div>
        <div>
          <FontAwesomeIcon icon={faPlusCircle} className="mr-2 text-gray-600"/>
          <span>{strings.canCreate}</span>
        </div>
        <div>
          <FontAwesomeIcon icon={faPen} className="mr-2 text-gray-600"/>
          <span>{strings.canUpdate}</span>
        </div>
        <div>
          <FontAwesomeIcon icon={faCheckCircle} className="mr-2 text-gray-600"/>
          <span>{strings.canApprove}</span>
        </div>
      </div>
    </SimpleModalForm>
  }
